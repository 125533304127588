import { locale } from '@/util'
import { REGEX } from '@/constants/constants'
import currencyFormat from '@/filters/currency-format'

export const formValidator = {
  requiredRules,
  addressRules,
  postCodeRules,
  realnameRules,
  usernameRules,
  passwordRules,
  emailRules,
  messengerRules,
  mobileNumberRules,
  referralCodeRules,
  verificationCodeRules,
  depositAmountRules,
  transferAmountRules,
  withdrawalAmountRules,
  dropDownListRules,
  walletTransferRules,
  confirmPasswordRules,
  dateRules,
  bankAccountNumberRules,
  bonusRequiredDepositAmountRules,
  depositAttachmentRules,
  captchaRules
}

function captchaRules() {
  return [requiredField(), invalidFieldFormat(), notAllowMultipleSpace()]
}

function depositAttachmentRules() {
  return [depositFileFormat()]
}

function bonusRequiredDepositAmountRules(bonusRequiredAmount, depositAmount, currency) {
  return [bonusRequiredDepositAmount(bonusRequiredAmount, depositAmount, currency)]
}

function requiredRules(min = 0, max = 0) {
  return [requiredField(), invalidFieldFormat(), notAllowMultipleSpace(), minMaxLength(min, max)]
}

function addressRules() {
  return [requiredField(), addressFormat(), notAllowMultipleSpace(), minMaxLength(1, 200)]
}

function postCodeRules() {
  return [requiredField(), integerFormat(), minMaxLength(5, 20)]
}

function realnameRules() {
  return [requiredField(), realnameFormat(), notAllowMultipleSpace(), minMaxLength(2, 50)]
}

function usernameRules(usernamePassToCheck) {
  return [requiredField(), minMaxLength(6, 13), usernameFormat(), notAllowMultipleSpace(), usernamePasswordRules(usernamePassToCheck)]
}

function passwordRules(usernamePassToCheck) {
  return [requiredField(), minMaxLength(6, 14), passwordFormat(), usernamePasswordRules(usernamePassToCheck)]
}

function walletTransferRules(targetValue, value) {
  return [requiredDropDownValue(), compareWalletRules(targetValue, value)]
}

function confirmPasswordRules(targetValue, value) {
  return [requiredField(), minMaxLength(6, 14), passwordFormat(), comparePasswordValue(targetValue, value)]
}

function emailRules() {
  return [requiredField(), emailFormat(), notAllowMultipleSpace(), minMaxLength(1, 50)]
}

function messengerRules() {
  return [requiredField(), messengerFormat(), notAllowMultipleSpace(), minMaxLength(6, 20)]
}

function mobileNumberRules(countryCode) {
  return [requiredField(), mobileNumberFormat(countryCode), notAllowMultipleSpace()]
}

function referralCodeRules() {
  return [referralCodeFormat(), notAllowMultipleSpace(), minMaxLength(6, 11)]
}

function verificationCodeRules() {
  return [requiredField(), minMaxLength(4, 6)]
}

function transferAmountRules(decimalPlace = 2) {
  return [requiredField(), transferDecimalFormat(decimalPlace)]
}

function depositAmountRules(decimalPlace = 2, min, max, currency) {
  return [requiredField(), depositDecimalFormat(decimalPlace), depositRangeValue(min, max, currency)]
}

function withdrawalAmountRules(decimalPlace = 2, min, max, currency) {
  return [requiredField(), withdrawalDecimalFormat(decimalPlace), withdrawalRangeValue(min, max, currency)]
}

function dropDownListRules() {
  return [requiredDropDownValue()]
}

function dateRules() {
  return [requiredField(), notAllowMultipleSpace()]
}

function bankAccountNumberRules(min = 0, max = 0) {
  return [requiredField(), invalidBankAccountFormat(), notAllowMultipleSpace(), minMaxLength(min, max)]
}

/* START Shared rules */
function requiredField() {
  return v => !!v || locale.getMessage('fieldErrorMessage.requiredField')
}

function requiredDropDownValue() {
  return v => (!!v && v != '') || locale.getMessage('fieldErrorMessage.requiredDropDownField')
}

function minMaxLength(min, max) {
  if (min == 0 && max == 0) {
    return true
  } else {
    return v => (v && v.length >= min && v.length <= max) || locale.getMessage('fieldErrorMessage.exceedMinMaxLength', [min, max])
  }
}

function compareWalletRules(targetValue, value) {
  return () => targetValue !== value || locale.getMessage('fieldErrorMessage.walletCannotBeSame')
}

function comparePasswordValue(targetValue, value) {
  return () => targetValue === value || locale.getMessage('fieldErrorMessage.confirmPasswordNotMatch')
}

function notAllowMultipleSpace() {
  return v => (v && REGEX.notAllowMultipleSpace.test(v)) || locale.getMessage('fieldErrorMessage.multipleSpace')
}

function bonusRequiredDepositAmount(bonusAmount, depositAmount, currency) {
  return () => parseFloat(bonusAmount) <= parseFloat(depositAmount) || locale.getMessage('fieldErrorMessage.depositAmountNotMeetBonusRequiredAmount', [currencyFormat(bonusAmount, currency)])
}

/* END Shared rules */

/* FORMAT */

function invalidFieldFormat() {
  return v => (v && REGEX.invalidCharacters.test(v)) || locale.getMessage('fieldErrorMessage.invalidFieldFormat')
}

function addressFormat() {
  return v => (v && REGEX.address.test(v)) || locale.getMessage('fieldErrorMessage.invalidAddressFormat')
}

function realnameFormat() {
  return v => (v && REGEX.realname.test(v)) || locale.getMessage('fieldErrorMessage.invalidRealnameFormat')
}

function usernameFormat() {
  return v => (v && REGEX.username.test(v)) || locale.getMessage('fieldErrorMessage.invalidUsernameFormat')
}

function passwordFormat() {
  return v => (v && REGEX.password.test(v)) || locale.getMessage('fieldErrorMessage.invalidPasswordFormat')
}

function emailFormat() {
  return v => (v && REGEX.email.test(v)) || locale.getMessage('fieldErrorMessage.invalidEmailFormat')
}

function messengerFormat() {
  return v => (v && REGEX.line.test(v)) || locale.getMessage('fieldErrorMessage.invalidMessengerFormat')
}

function mobileNumberFormat(countryCode) {
  let mobileNumberRegex = REGEX.mobileNumber
  switch (countryCode) {
    case 'cn':
      mobileNumberRegex = REGEX.mobileNumberChina
      break
    case 'bd':
      mobileNumberRegex = REGEX.mobileNumberBDT
      break
    default:
      break
  }
  return v => (v && mobileNumberRegex.test(v)) || locale.getMessage(`fieldErrorMessage.invalidMobileNumberFormat${countryCode.toUpperCase()}`)
}

function referralCodeFormat() {
  return v => (v && REGEX.referralCode.test(v)) || locale.getMessage('fieldErrorMessage.invalidReferralCodeFormat')
}

function transferDecimalFormat(decimalPlace) {
  let reg = REGEX.decimalTwo
  let msg = 'invalidTransferTwoDecimalField'

  switch (decimalPlace) {
    case 0:
      reg = REGEX.integer
      msg = 'invalidTransferZeroDecimalField'
      break
    default:
      break
  }

  return v => reg.test(v) || locale.getMessage(`fieldErrorMessage.${msg}`)
}

function depositDecimalFormat(decimalPlace) {
  let reg = REGEX.decimalTwo
  let msg = 'invalidDepositTwoDecimalField'

  switch (decimalPlace) {
    case 0:
      reg = REGEX.integer
      msg = 'invalidDepositZeroDecimalField'
      break
    default:
      break
  }

  return v => reg.test(v) || locale.getMessage(`fieldErrorMessage.${msg}`)
}

function withdrawalDecimalFormat(decimalPlace) {
  let reg = REGEX.decimalTwo
  let msg = 'invalidWithdrawalTwoDecimalField'

  switch (decimalPlace) {
    case 0:
      reg = REGEX.integer
      msg = 'invalidWithdrawalZeroDecimalField'
      break
    default:
      break
  }

  return v => reg.test(v) || locale.getMessage(`fieldErrorMessage.${msg}`)
}

function depositRangeValue(min, max, currency) {
  if (parseFloat(max) === 0) {
    return v => parseFloat(v) >= parseFloat(min) || locale.getMessage('fieldErrorMessage.invalidDepositExceedField', [currencyFormat(min, currency), '-'])
  } else {
    return v =>
      (parseFloat(v) >= parseFloat(min) && parseFloat(v) <= parseFloat(max)) ||
      locale.getMessage('fieldErrorMessage.invalidDepositExceedField', [currencyFormat(min, currency), currencyFormat(max, currency)])
  }
}

function withdrawalRangeValue(min, max, currency) {
  if (parseFloat(max) === 0) {
    return v => parseFloat(v) >= parseFloat(min) || locale.getMessage('fieldErrorMessage.invalidWithdrawalExceedField', [currencyFormat(min, currency), '-'])
  } else {
    return v =>
      (parseFloat(v) >= parseFloat(min) && parseFloat(v) <= parseFloat(max)) ||
      locale.getMessage('fieldErrorMessage.invalidWithdrawalExceedField', [currencyFormat(min, currency), currencyFormat(max, currency)])
  }
}

function invalidBankAccountFormat() {
  return v => (v && REGEX.integer.test(v)) || locale.getMessage('fieldErrorMessage.invalidBankAccountNumberFormat')
}

function integerFormat() {
  return v => (v && REGEX.number.test(v)) || locale.getMessage('fieldErrorMessage.integerFormat')
}

function depositFileFormat() {
  return v => v.type.includes('image') || locale.getMessage('fieldErrorMessage.invalidDepositAttachementFileFormat')
}

function usernamePasswordRules(usernamePassToCheck) {
  return v => v !== usernamePassToCheck || locale.getMessage('fieldErrorMessage.usernamePasswordSame')
}
/* END FORMAT*/
