import {paymentService} from '@/services'
import {SHARED_LOADING, SHARED_UNLOADING} from '@/store/shared.module'
import format from 'string-format'

const MODULE_NAME = 'payment/'

const A_TRANSACTION = 'getTransactionHistory'
const A_AVAILABLE_METHOD = 'getAvailableMethod'
const A_MERCHANT_BANK_ACCOUNT = 'getMerchantBankAccount'
const A_MEMBER_BANK_ACCOUNT = 'getMemberBankAccount'
const A_ONLINE_PAYMENT_SUPPORTED_BANK = 'getOnlinePaymentSupportedBank'
const A_SUBMIT_PAYMENT_TRANSACTION = 'postPaymentTransaction'
const A_RESET_TRANSACTION_RESPONSE_STORE = 'resetTransactionResponseStore'
const A_BANK = 'getBank'
const A_NEW_MEMBER_BANK_ACCOUNT = 'postNewMemberBankAccount'
const A_RESET_NEW_MEMBER_BANK_ACCOUNT_STORE = 'resetNewMemberBankkAccountStore'
const A_DELETE_MEMBER_BANK_ACCOUNT = 'deleteMemberBankAccount'
const A_RESET_DELETE_MEMBER_BANK_ACCOUNT_STORE = 'resetDeleteMemberBankAccountStore'
const A_UPDATE_MEMBER_BANK_ACCOUNT = 'updateMemberBankAccount'
const A_RESET_UPDATE_MEMBER_BANK_ACCOUNT_STORE = 'resetUpdateMemberBankAccountStore'
const A_RESET_HISTORY = 'resetTransactionHistory'
const A_PROCESS_TRANSACTION = 'processTransaction'
const A_RESET_PAYMENT_PROCESS_TRANSACTION_RESPONSE_STORE = 'resetProcessTransactionStore'
const A_CANCEL_TRANSACTION = 'cancelTransaction'
const A_RESET_CANCEL_TRANSACTION_STORE = 'resetCancelTransactionStore'
const A_GET_PAYMEMT_HISTORY = 'getPaymentHistory'
const A_SUBMIT_PAYMENT_WITHDRAWAL_TRANSACTION = 'submitPaymentWithdrawal'
const A_RESET_TRANSACTION_WITHDRAWAL_RESPONSE_STORE = 'resetSubmitPaymentWithdrawal'
const A_GET_DEPOSIT_PERCENTAGE_CONFIGURATION = "getDepositPercentageConfiguration";

export const PAYMENT_TRANSACTION = MODULE_NAME + A_TRANSACTION
export const PAYMENT_AVAILABLE_METHOD = MODULE_NAME + A_AVAILABLE_METHOD
export const PAYMENT_MERCHANT_BANK_ACCOUNT = MODULE_NAME + A_MERCHANT_BANK_ACCOUNT
export const PAYMENT_MEMBER_BANK_ACCOUNT = MODULE_NAME + A_MEMBER_BANK_ACCOUNT
export const PAYMENT_ONLINE_PAYMENT_SUPPORTED_BANK = MODULE_NAME + A_ONLINE_PAYMENT_SUPPORTED_BANK
export const PAYMENT_SUBMIT_PAYMENT_TRANSACTION = MODULE_NAME + A_SUBMIT_PAYMENT_TRANSACTION
export const PAYMENT_RESET_TRANSACTION_RESPONSE_STORE = MODULE_NAME + A_RESET_TRANSACTION_RESPONSE_STORE
export const PAYMENT_SUBMIT_PAYMENT_WITHDRAWAL_TRANSACTION = MODULE_NAME + A_SUBMIT_PAYMENT_WITHDRAWAL_TRANSACTION
export const PAYMENT_RESET_TRANSACTION_WITHDRAWAL_RESPONSE_STORE = MODULE_NAME + A_RESET_TRANSACTION_WITHDRAWAL_RESPONSE_STORE
export const PAYMENT_BANK = MODULE_NAME + A_BANK
export const PAYMENT_NEW_MEMBER_BANK_ACCOUNT = MODULE_NAME + A_NEW_MEMBER_BANK_ACCOUNT
export const PAYMENT_RESET_NEW_MEMBER_BANK_ACCOUNT_STORE = MODULE_NAME + A_RESET_NEW_MEMBER_BANK_ACCOUNT_STORE
export const PAYMENT_DELETE_MEMBER_BANK_ACCOUNT = MODULE_NAME + A_DELETE_MEMBER_BANK_ACCOUNT
export const PAYMENT_RESET_DELETE_MEMBER_BANK_ACCOUNT_STORE = MODULE_NAME + A_RESET_DELETE_MEMBER_BANK_ACCOUNT_STORE
export const PAYMENT_UPDATE_MEMBER_BANK_ACCOUNT = MODULE_NAME + A_UPDATE_MEMBER_BANK_ACCOUNT
export const PAYMENT_RESET_UPDATE_MEMBER_BANK_ACCOUNT_STORE = MODULE_NAME + A_RESET_UPDATE_MEMBER_BANK_ACCOUNT_STORE
export const PAYMENT_RESET_HISTORY = MODULE_NAME + A_RESET_HISTORY
export const PAYMENT_PROCESS_TRANSACTION = MODULE_NAME + A_PROCESS_TRANSACTION
export const PAYMENT_RESET_PAYMENT_PROCESS_TRANSACTION_RESPONSE_STORE = MODULE_NAME + A_RESET_PAYMENT_PROCESS_TRANSACTION_RESPONSE_STORE
export const PAYMENT_CANCEL_TRANSACTION = MODULE_NAME + A_CANCEL_TRANSACTION
export const PAYMENT_RESET_CANCEL_TRANSACTION_STORE = MODULE_NAME + A_RESET_CANCEL_TRANSACTION_STORE
export const PAYMENT_HISTORY = MODULE_NAME + A_GET_PAYMEMT_HISTORY
export const DEPOSIT_PERCENTAGE_CONFIGURATION = MODULE_NAME + A_GET_DEPOSIT_PERCENTAGE_CONFIGURATION;


const M_TRANSACTION = 'setTransactionHistory'
const M_AVAILABLE_METHOD = 'setAvailableMethod'
const M_MERCHANT_BANK_ACCOUNT = 'setMerchantBankAccount'
const M_MEMBER_BANK_ACCOOUNT = 'setMemberBankAccount'
const M_ONLINE_PAYMENT_SUPPORTED_BANK = 'setOnlinePaymentSupportedBank'
const M_SUBMIT_PAYMENT_TRANSACTION = 'responsePostPaymentTransaction'
const M_RESET_TRANSACTION_RESPONSE_STORE = 'setResetTransactionResponseStore'
const M_SUBMIT_PAYMENT_WITHDRAWAL_TRANSACTION = 'responsePostPaymentWithdrawalTransaction'
const M_RESET_TRANSACTION_WITHDRAWAL_RESPONSE_STORE = 'setResetTransactionWithdrawalResponseStore'
const M_BANK = 'setBank'
const M_NEW_MEMBER_BANK_ACCOUNT = 'responstPostNewMemberBankAccount'
const M_RESET_NEW_MEMBER_BANK_ACCOUNT_STORE = 'resetNewMemberBankAccountStore'
const M_DELETE_MEMBER_BANK_ACCOUNT = 'responseDeleteMemberBankAccount'
const M_RESET_DELETE_MEMBER_BANK_ACCOUNT_STORE = 'resetDeleteMemberBankAccountStore'
const M_UPDATE_MEMBER_BANK_ACCOUNT = 'responseUpdateMemberBankAccount'
const M_RESET_UPDATE_MEMBER_BANK_ACCOUNT_STORE = 'resetUpdateMemberBankAccountStore'
const M_RESET_HISTORY = 'resetTransactionHistory'
const M_PROCESS_TRANSACTION = 'processTransactionComplete'
const M_RESET_PAYMENT_PROCESS_TRANSACTION_RESPONSE_STORE = 'resetProcessTransactionStoreComplete'
const M_CANCEL_TRANSACTION = 'cancelTransactionComplete'
const M_RESET_CANCEL_TRANSACTION_STORE = 'resetCancelTransactionCompleteStore'
const M_PAYMEMT_HISTORY = 'setPaymentHistory'
const M_DEPOSIT_PERCENTAGE_CONFIGURATION = "setDepositPercentageConfiguration";

const state = {
    depositPercentageConfiguration: 0,
    newMemberBankAccountResponse: {
        action: 'newMemberBankAccount',
        complete: false,
        success: false,
        code: 0
    },
    deleteMemberBankAccountResponse: {
        action: 'deleteMemberBankAccount',
        complete: false,
        success: false,
        code: 0
    },
    updateMemberBankAccountResponse: {
        action: 'updateMemberBankAccount',
        complete: false,
        success: false,
        code: 0
    },
    transactionHistory: [],
    availableDepositMethods: [],
    availableWithdrawalMethods: [],
    merchantBankAccounts: [],
    memberBankAccounts: [],
    onlinePaymentSupportedBanks: [],
    trasnactionResponse: {},
    transactionWithdrawalResponse: {},
    banks: [],
    pagination: {
        pageNumber: 0,
        pageSize: 0,
        lastPage: 0,
        tableRowsCount: 0
    },
    processTransactionResponse: {
        action: 'processTransaction',
        complete: false,
        success: false,
        code: 0,
        data: '',
        error: ''
    },
    cancelTransactionResponse: {
        action: 'cancelTransaction',
        complete: false,
        success: false,
        code: 0
    },
    paymentHistory: {
        type: '',
        data: [],
        pagination: {}
    }
}

const getters = {}

const actions = {
    [A_GET_DEPOSIT_PERCENTAGE_CONFIGURATION]({ dispatch, commit }) {
        dispatch(`${SHARED_LOADING}`, {}, { root: true });
        paymentService.getDepositPercentConfiguration().then(
          data => {
              let result = data;
              dispatch(`${SHARED_UNLOADING}`, {}, { root: true });
              commit(M_DEPOSIT_PERCENTAGE_CONFIGURATION, { result });
          },
          error => {
              let result = error;
              dispatch(`${SHARED_UNLOADING}`, {}, { root: true });
              commit(M_DEPOSIT_PERCENTAGE_CONFIGURATION, { result });
          }
        );
    },
    [A_TRANSACTION]({dispatch, commit}, {obj}) {
        dispatch(`${SHARED_LOADING}`, {}, {root: true})
        let isPagination = obj.isPagination
        paymentService.getTransaction(obj).then(
            data => {
                let result = data
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_TRANSACTION, {result, isPagination})
            },
            error => {
                let result = error
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_TRANSACTION, {result, isPagination})
            }
        )
    },
    [A_AVAILABLE_METHOD]({dispatch, commit}, {obj}) {
        dispatch(`${SHARED_LOADING}`, {}, {root: true})
        paymentService.getAvailableMethod(obj).then(
            data => {
                let result = data
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_AVAILABLE_METHOD, {result})
            },
            error => {
                let result = error
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_AVAILABLE_METHOD, {result})
            }
        )
    },
    [A_MERCHANT_BANK_ACCOUNT]({dispatch, commit}, {obj}) {
        dispatch(`${SHARED_LOADING}`, {}, {root: true})
        paymentService.getMerchantBankAccount(obj).then(
            data => {
                let result = data
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_MERCHANT_BANK_ACCOUNT, {result})
            },
            error => {
                let result = error
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_MERCHANT_BANK_ACCOUNT, {result})
            }
        )
    },
    [A_MEMBER_BANK_ACCOUNT]({commit}, {obj}) {
        paymentService.getMemberBankAccount(obj).then(
            data => {
                let result = data
                commit(M_MEMBER_BANK_ACCOOUNT, {result})
            },
            error => {
                let result = error
                commit(M_MEMBER_BANK_ACCOOUNT, {result})
            }
        )
    },
    [A_ONLINE_PAYMENT_SUPPORTED_BANK]({commit}, {obj}) {
        paymentService.getOnlinePaymentSupportedBank(obj).then(
            data => {
                let result = data
                commit(M_ONLINE_PAYMENT_SUPPORTED_BANK, {result})
            },
            error => {
                let result = error
                commit(M_ONLINE_PAYMENT_SUPPORTED_BANK, {result})
            }
        )
    },
    [A_SUBMIT_PAYMENT_WITHDRAWAL_TRANSACTION]({dispatch, commit}, {obj}) {
        dispatch(`${SHARED_LOADING}`, {}, {root: true})
        paymentService.postTransaction(obj).then(
            data => {
                let result = data
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_SUBMIT_PAYMENT_WITHDRAWAL_TRANSACTION, {result})
            },
            error => {
                let result = error
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_SUBMIT_PAYMENT_WITHDRAWAL_TRANSACTION, {result})
            }
        )
    },
    [A_SUBMIT_PAYMENT_TRANSACTION]({dispatch, commit}, {obj}) {
        dispatch(`${SHARED_LOADING}`, {}, {root: true})
        paymentService.postTransaction(obj).then(
            data => {
                let result = data
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_SUBMIT_PAYMENT_TRANSACTION, {result})
            },
            error => {
                let result = error
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_SUBMIT_PAYMENT_TRANSACTION, {result})
            }
        )
    },
    [A_RESET_TRANSACTION_WITHDRAWAL_RESPONSE_STORE]({commit}) {
        commit(M_RESET_TRANSACTION_WITHDRAWAL_RESPONSE_STORE)
    },
    [A_RESET_TRANSACTION_RESPONSE_STORE]({commit}) {
        commit(M_RESET_TRANSACTION_RESPONSE_STORE)
    },
    [A_BANK]({commit}, {obj}) {
        paymentService.getBank(obj).then(
            data => {
                let result = data
                commit(M_BANK, {result})
            },
            error => {
                let result = error
                commit(M_BANK, {result})
            }
        )
    },
    [A_NEW_MEMBER_BANK_ACCOUNT]({dispatch, commit}, {bankAccountObj}) {
        dispatch(`${SHARED_LOADING}`, {}, {root: true})
        paymentService.newMemberBankAccount(bankAccountObj).then(
            data => {
                let result = data
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_NEW_MEMBER_BANK_ACCOUNT, {result})
            },
            error => {
                let result = error
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_NEW_MEMBER_BANK_ACCOUNT, {result})
            }
        )
    },
    [A_RESET_NEW_MEMBER_BANK_ACCOUNT_STORE]({commit}) {
        commit(M_RESET_NEW_MEMBER_BANK_ACCOUNT_STORE)
    },
    [A_DELETE_MEMBER_BANK_ACCOUNT]({dispatch, commit}, {obj}) {
        dispatch(`${SHARED_LOADING}`, {}, {root: true})
        paymentService.deleteMemberBankAccount(obj).then(
            data => {
                let result = data
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_DELETE_MEMBER_BANK_ACCOUNT, {result})
            },
            error => {
                let result = error
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_DELETE_MEMBER_BANK_ACCOUNT, {result})
            }
        )
    },
    [A_RESET_DELETE_MEMBER_BANK_ACCOUNT_STORE]({commit}) {
        commit(M_RESET_DELETE_MEMBER_BANK_ACCOUNT_STORE)
    },
    [A_UPDATE_MEMBER_BANK_ACCOUNT]({dispatch, commit}, {obj}) {
        dispatch(`${SHARED_LOADING}`, {}, {root: true})
        paymentService.putMemberBankAccount(obj).then(
            data => {
                let result = data
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_UPDATE_MEMBER_BANK_ACCOUNT, {result})
            },
            error => {
                let result = error
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_UPDATE_MEMBER_BANK_ACCOUNT, {result})
            }
        )
    },
    [A_RESET_UPDATE_MEMBER_BANK_ACCOUNT_STORE]({commit}) {
        commit(M_RESET_UPDATE_MEMBER_BANK_ACCOUNT_STORE)
    },

    [A_RESET_HISTORY]({commit}) {
        commit(M_RESET_HISTORY)
    },
    [A_PROCESS_TRANSACTION]({dispatch, commit}, {processTransactionObj}) {
        dispatch(`${SHARED_LOADING}`, {}, {root: true})
        paymentService.processTransaction(processTransactionObj).then(
            data => {
                let result = data
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_PROCESS_TRANSACTION, {result})
            },
            error => {
                let result = error
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_PROCESS_TRANSACTION, {result})
            }
        )
    },
    [A_RESET_PAYMENT_PROCESS_TRANSACTION_RESPONSE_STORE]({commit}) {
        commit(M_RESET_PAYMENT_PROCESS_TRANSACTION_RESPONSE_STORE)
    },
    [A_CANCEL_TRANSACTION]({dispatch, commit}, {cancelTransactionObj}) {
        dispatch(`${SHARED_LOADING}`, {}, {root: true})
        paymentService.cancelTransaction(cancelTransactionObj).then(
            data => {
                let result = data
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_CANCEL_TRANSACTION, {result})
            },
            error => {
                let result = error
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_CANCEL_TRANSACTION, {result})
            }
        )
    },
    [A_RESET_CANCEL_TRANSACTION_STORE]({commit}) {
        commit(M_RESET_CANCEL_TRANSACTION_STORE)
    },
    [A_GET_PAYMEMT_HISTORY]({dispatch, commit}, {obj}) {
        dispatch(`${SHARED_LOADING}`, {}, {root: true})
        paymentService.getPaymentHistory(obj).then(
            data => {
                let result = data
                result.type = obj.type
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_PAYMEMT_HISTORY, {result})
            },
            error => {
                let result = error
                dispatch(`${SHARED_UNLOADING}`, {}, {root: true})
                commit(M_PAYMEMT_HISTORY, {result})
            }
        )
    }
}

const mutations = {
    [M_DEPOSIT_PERCENTAGE_CONFIGURATION](state, { result }) {
        if (result.success) {
            state.depositPercentageConfiguration = result.data != 0 && result.percent_value != "0" ? result.percent_value : 0;
        }
    },
    [M_TRANSACTION](state, {result, isPagination}) {
        if (result.success) {
            let d = result.data
            state.pagination = {
                pageNumber: result.pagination.current_page,
                pageSize: Number(result.pagination.per_page),
                lastPage: result.pagination.last_page,
                tableRowsCount: result.pagination.total
            }

            let p = []

            d.forEach(x => {
                p.push({
                    transactionId: x.transID,
                    amount: x.amount,
                    status:
                        x.transStatus.toLowerCase() == 'approved'
                            ? 'success'
                            : x.transStatus.toLowerCase() == 'rejected'
                                ? 'fail'
                                : x.transStatus.toLowerCase() == 'processing' || x.transStatus.toLowerCase() == 'escalated'
                                    ? 'process'
                                    : x.transStatus.toLowerCase() == 'cancelled'
                                        ? 'cancelled'
                                        : 'pending',
                    type: x.transType.toLowerCase(),
                    method: x.methodName,
                    methodCode: x.methodCode,
                    date: x.createdAt,
                    actualStatus:
                        x.transStatus.toLowerCase() == 'approved'
                            ? 'success'
                            : x.transStatus.toLowerCase() == 'rejected'
                                ? 'fail'
                                : x.transStatus.toLowerCase() == 'processing' || x.transStatus.toLowerCase() == 'escalated'
                                    ? 'process'
                                    : x.transStatus.toLowerCase() == 'cancelled'
                                        ? 'cancelled'
                                        : 'pending'
                })
            })

            if (isPagination) {
                state.transactionHistory = p
            } else {
                state.transactionHistory.concat(p)
            }
        }
    },
    [M_AVAILABLE_METHOD](state, {result}) {
        let p = []
        let w = []
        if (result.success) {
            let d = result.data

            if (d.deposit) {
                d.deposit.forEach(method => {
                    if (method.method_code != null && method.method_code != undefined) {
                        if (method.gateways.length < 1) return
                        mapMethod(method, p)
                    }
                })
            }

            if (d.withdrawal) {
                d.withdrawal.forEach(method => {
                    if (method.method_code != null && method.method_code != undefined) {
                        if (method.gateways.length < 1) return
                        mapMethod(method, w)
                    }
                })
            }

            function mapMethod(method, array) {
                let bankAccounts = !method.gateways[0].bank_accounts ? [] :
                    method.gateways[0].bank_accounts.map(b => ({
                        accountName: b.account_name,
                        accountNumber: b.account_number,
                        displayText: `${b.account_number} - ${b.account_name}`,
                        value: b.bank_account_code
                    }))
                let banks = !method.gateways[0].banks ? [] :
                    method.gateways[0].banks.map(b => ({
                        name: b.bank_name,
                        code: b.bank_code
                    }))
                let localization = !method.method_localization ? [] :
                    method.method_localization.map(l => ({
                        language: l.language,
                        description: l.description
                    }))
                array.push({
                    code: method.method_code,
                    sort: 1,
                    isActive: method.gateways[0].is_active,
                    minAmount: method.gateways[0].gateway_min,
                    maxAmount: method.gateways[0].gateway_max,
                    gatewayProviderCode: method.gateways.map(g => ({
                        gatewayCode: g.gateway_code,
                        gatewayName: g.gateway_name,
                        bankAccount: g.bank_account
                    })),
                    transactionType: method.transaction_type,
                    name: method.method_name,
                    bankAccounts,
                    banks,
                    localization
                })
            }
        }
        state.availableDepositMethods = p
        state.availableWithdrawalMethods = w
    },
    [M_MERCHANT_BANK_ACCOUNT](state, {result}) {
        let p = []
        if (result.success) {
            let d = result.data
            d.forEach(bankAccount => {
                if (bankAccount.bank != null && bankAccount.bank != undefined) {
                    p.push({
                        id: bankAccount.id,
                        displayText: format(
                            '{0} - {1}',
                            bankAccount.bank.name,
                            bankAccount.accountNumber.length > 6 ? bankAccount.accountNumber.substr(bankAccount.accountNumber.length - 6) : bankAccount.accountNumber
                        ),
                        name: bankAccount.bank.name,
                        value: bankAccount.shortCode,
                        accountName: bankAccount.accountName,
                        accountNumber: bankAccount.accountNumber
                    })
                }
            })
        }
        p.push({
            id: '0',
            displayText: 'otherBank',
            name: '',
            value: '0',
            accountName: '',
            accountNumber: ''
        })

        state.merchantBankAccounts = p
    },
    [M_MEMBER_BANK_ACCOOUNT](state, {result}) {
        let p = []
        if (result.success) {
            let d = result.data
            d.forEach(memberBankAccount => {
                p.push({
                    id: memberBankAccount.id,
                    displayText: format('{0}', memberBankAccount.account_name),
                    value: memberBankAccount.id,
                    bankName: memberBankAccount.bank_name,
                    accountName: memberBankAccount.account_name,
                    accountNumber: memberBankAccount.account_number
                })
            })
        }
        // p.push({
        //   id: '0',
        //   displayText: 'newBankAccount',
        //   value: '0',
        //   name: '',
        //   accountName: '',
        //   accountNumber: ''
        // })
        state.memberBankAccounts = p
    },
    [M_ONLINE_PAYMENT_SUPPORTED_BANK](state, {result}) {
        let p = []
        if (result.success) {
            let d = result.data
            d.forEach(onlineSupportedBank => {
                p.push({
                    id: onlineSupportedBank.id,
                    value: onlineSupportedBank.id,
                    displayText: onlineSupportedBank.name,
                    code: onlineSupportedBank.id,
                    bankCode: onlineSupportedBank.bankCode
                })
            })
        }
        state.onlinePaymentSupportedBanks = p
    },
    [M_SUBMIT_PAYMENT_WITHDRAWAL_TRANSACTION](state, {result}) {
        if (result.success) {
            let d = result.data
            state.transactionWithdrawalResponse = {
                id: d.trans_id,
                type: d.transType,
                methodName: d.method,
                status: d.transStatus,
                amount: d.amount,
                remark: d.remark,
                complete: true,
                success: result.success,
                code: result.code,
                error: result.message,
                data: d
            }
        } else {
            state.transactionWithdrawalResponse = {
                complete: true,
                success: result.success,
                code: result.code,
                error: result.error
            }
        }
    },
    [M_RESET_TRANSACTION_WITHDRAWAL_RESPONSE_STORE](state) {
        state.transactionWithdrawalResponse = {
            complete: false,
            success: false,
            code: 0,
            error: ''
        }
    },
    [M_SUBMIT_PAYMENT_TRANSACTION](state, {result}) {
        if (result.success) {
            let d = result.data
            state.trasnactionResponse = {
                id: d.trans_id,
                type: d.transType,
                methodName: d.method,
                status: d.transStatus,
                amount: d.amount,
                remark: d.remark,
                complete: true,
                success: result.success,
                code: result.code,
                error: result.message,
                data: d
            }
        } else {
            state.trasnactionResponse = {
                complete: true,
                success: result.success,
                code: result.code,
                error: result.error
            }
        }
    },
    [M_RESET_TRANSACTION_RESPONSE_STORE](state) {
        state.trasnactionResponse = {
            complete: false,
            success: false,
            code: 0,
            error: ''
        }
    },
    [M_BANK](state, {result}) {
        let p = []
        if (result.success) {
            let d = result.data
            d.forEach(bank => {
                p.push({
                    id: bank.id,
                    displayText: bank.name,
                    value: bank.code,
                    code: bank.code,
                    name: bank.name
                })
            })
        }
        p.push({
            id: 0,
            displayText: 'other',
            value: 0,
            code: 'other',
            name: 'other'
        })

        state.banks = p
    },
    [M_NEW_MEMBER_BANK_ACCOUNT](state, {result}) {
        state.newMemberBankAccountResponse = {
            action: 'newMemberBankAccount',
            complete: true,
            success: result.success,
            code: result.code
        }
    },
    [M_RESET_NEW_MEMBER_BANK_ACCOUNT_STORE](state) {
        state.newMemberBankAccountResponse = {
            action: 'newMemberBankAccount',
            complete: false,
            success: false,
            code: 0
        }
    },
    [M_DELETE_MEMBER_BANK_ACCOUNT](state, {result}) {
        state.deleteMemberBankAccountResponse = {
            action: 'deleteMemberBankAccount',
            complete: true,
            success: result.success,
            code: result.code
        }
    },
    [M_RESET_DELETE_MEMBER_BANK_ACCOUNT_STORE](state) {
        state.deleteMemberBankAccountResponse = {
            action: 'deleteMemberBankAccount',
            complete: false,
            success: false,
            code: 0
        }
    },
    [M_UPDATE_MEMBER_BANK_ACCOUNT](state, {result}) {
        state.updateMemberBankAccountResponse = {
            action: 'updateMemberBankAccount',
            complete: true,
            success: result.success,
            code: result.code
        }
    },
    [M_RESET_UPDATE_MEMBER_BANK_ACCOUNT_STORE](state) {
        state.updateMemberBankAccountResponse = {
            action: 'updateMemberBankAccount',
            complete: false,
            success: false,
            code: 0
        }
    },
    [M_RESET_HISTORY](state) {
        state.transactionHistory = []
    },
    [M_PROCESS_TRANSACTION](state, {result}) {
        state.processTransactionResponse = {
            action: 'processTransaction',
            complete: true,
            success: result.success,
            code: result.code,
            data: result.data,
            error: typeof result.error != 'undefined' && result.error != null ? result.error : null
        }
    },
    [M_RESET_PAYMENT_PROCESS_TRANSACTION_RESPONSE_STORE](state) {
        state.processTransactionResponse = {
            action: 'processTransaction',
            complete: false,
            success: false,
            code: 0,
            data: '',
            error: ''
        }
    },
    [M_CANCEL_TRANSACTION](state, {result}) {
        state.cancelTransactionResponse = {
            action: 'cancelTransaction',
            complete: true,
            success: result.success,
            code: result.code
        }
        if (result.success) {
            let d = result.data
            state.transactionHistory.find(x => x.transactionId == d.transID).status =
                d.transStatus.toLowerCase() == 'approved'
                    ? 'success'
                    : d.transStatus.toLowerCase() == 'rejected'
                        ? 'fail'
                        : d.transStatus.toLowerCase() == 'processing' || d.transStatus.toLowerCase() == 'escalated'
                            ? 'process'
                            : d.transStatus.toLowerCase() == 'cancelled'
                                ? 'cancelled'
                                : 'pending'

            state.transactionHistory.find(x => x.transactionId == d.transID).actualStatus =
                d.transStatus.toLowerCase() == 'approved'
                    ? 'success'
                    : d.transStatus.toLowerCase() == 'rejected'
                        ? 'fail'
                        : d.transStatus.toLowerCase() == 'processing' || d.transStatus.toLowerCase() == 'escalated'
                            ? 'process'
                            : d.transStatus.toLowerCase() == 'cancelled'
                                ? 'cancelled'
                                : 'pending'
        }
    },
    [M_RESET_CANCEL_TRANSACTION_STORE](state) {
        state.cancelTransactionResponse = {
            action: 'cancelTransaction',
            complete: false,
            success: false,
            code: 0
        }
    },
    [M_PAYMEMT_HISTORY](state, {result}) {
        if (result.success) {
            state.paymentHistory.data = result.data
            state.paymentHistory.type = result.type
            state.paymentHistory.pagination = result.pagination
        } else {
            state.paymentHistory = {
                type: '',
                data: [],
                pagination: {}
            }
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
