var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-navigator hidden-sm-and-down"},[_c('div',{staticClass:"page-navigator-wrapper"},[(_vm.isDAndroid||_vm.isDesktop)?_c('div',{staticClass:"page-navigator-item"},[_c('v-btn',{staticClass:"page-navigator-button",attrs:{"active-class":"page-navigator-button--active","text":"","ripple":false,"href":"/jaya9_2024_new.apk"}},[_c('v-icon',[_vm._v("system_update")])],1)],1):_vm._e(),_c('div',{staticClass:"page-navigator-item"},[_c('v-btn',{staticClass:"page-navigator-button",attrs:{"active-class":"page-navigator-button--active","text":"","ripple":false,"href":"","to":{ name: _vm.routeName.HOME }}},[_c('v-icon',[_vm._v("home")])],1)],1),_vm._l((_vm.storageGameProviderType),function(gameType){return _c('div',{key:gameType.type,staticClass:"page-navigator-item"},[_c('v-menu',{staticClass:"mt-2",attrs:{"open-on-hover":"","bottom":"","offset-y":"","min-width":"100%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"page-navigator-button",attrs:{"active-class":"page-navigator-button--active","text":"","ripple":false,"href":"","to":{
              name:
                gameType.type == 'sb'
                  ? _vm.routeName.SPORTSBOOK
                  : gameType.type == 'rng'
                  ? _vm.routeName.SLOT
                  : gameType.type == 'ld'
                  ? _vm.routeName.CASINO
                  : gameType.type == 'fishing'
                  ? _vm.routeName.FISHING
                  : gameType.type == 'special'
                  ? _vm.routeName.SPECIAL
                  : gameType.type == 'table'
                  ? _vm.routeName.TABLE_GAMES
                  : gameType.type == 'lottery'
                  ? _vm.routeName.LOTTERY
                  : gameType.type == 'cricket'
                  ? _vm.routeName.CRICKET
                  : gameType.type == 'crash'
                  ? _vm.routeName.CRASH
                  : _vm.routeName.HOME
            }}},on),[_c('label',{staticClass:"menu-navigator text-center text-capitalize"},[_vm._v(_vm._s(_vm.$t(("label." + (gameType.type)))))]),(gameType.type != 'fishing' && gameType.type != 'special')?_c('v-icon',[_vm._v("keyboard_arrow_down")]):_vm._e()],1)]}}],null,true)},[_c('div',{staticClass:"page-sub-navigator elevation-2"},[_c('div',{staticClass:"page-sub-navigator-wrapper"},_vm._l((gameType.providers),function(provider){return _c('div',{key:provider.providerCode,staticClass:"page-sub-navigator-item"},[_c('v-card',{staticClass:"elevation-0 game_card text-center align-center mr-0"},[_c('v-card-text',{staticClass:"font-weight-bold vendor-text text-center",staticStyle:{"top":"178px"}},[_vm._v("\n                  "+_vm._s(_vm.stringFormat("{0}", _vm.$t(("gameVendor." + (provider.providerCode) + "_" + (gameType.type) + "_long"))))+"\n                  "),(_vm.isHotGame(provider.providerCode))?_c('img',{staticClass:"img-hot",attrs:{"src":"/static/image/icon/hot-icon.png"}}):(_vm.isNewGame(provider.providerCode))?_c('img',{staticClass:"img-new",attrs:{"src":"/static/image/icon/new_icon.png"}}):_vm._e()]),_c('v-hover',{attrs:{"open-delay":"0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-img',{staticClass:"item-image",attrs:{"aspect-ratio":384 / 372,"contain":"","src":(_vm.mediaUrl + "/menu/" + (gameType.type) + "/" + (hover ? provider.providerCode + '_hover' : provider.providerCode) + ".webp"),"alt":("" + (+provider.providerCode))},on:{"click":function($event){return _vm.accessGame(gameType.type, provider.providerCode)},"mouseover":function($event){return _vm.submenuOnMouseOver($event.currentTarget, gameType.type, provider.providerCode, true)},"mouseleave":function($event){return _vm.submenuOnMouseOver($event.currentTarget, gameType.type, provider.providerCode, false)}}})]}}],null,true)}),(_vm.isGameMaintenance(gameType.type, provider.providerCode))?_c('div',{staticClass:"item-image item-maintenance"},[_c('span',{staticClass:"item-maintenance-label white--text"},[_vm._v(_vm._s(_vm.$t("label.underMaintenance")))])]):_vm._e()],1)],1)}),0)])])],1)}),_c('div',{staticClass:"page-navigator-item"},[_c('v-btn',{staticClass:"page-navigator-button",attrs:{"active-class":"page-navigator-button--active","text":"","ripple":false,"href":"","to":{ name: _vm.routeName.PROMOTION }}},[_c('label',{staticClass:"text-center text-capitalize",staticStyle:{"cursor":"pointer"}},[_vm._v(_vm._s(_vm.$t("label.promotion")))])])],1),_c('div',{staticClass:"page-navigator-item"},[_c('v-badge',{attrs:{"right":"","overlap":"","color":"light","offset-x":"11","offset-y":"19"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-img',{attrs:{"src":"/static/svg/game-icon-new.svg"}})]},proxy:true}])},[_c('v-btn',{staticClass:"page-navigator-button",attrs:{"active-class":"page-navigator-button--active","text":"","ripple":false,"href":"","to":{ name: _vm.routeName.VIP }}},[_c('label',{staticClass:"text-center text-capitalize",staticStyle:{"cursor":"pointer"}},[_vm._v(_vm._s(_vm.$t("label.bettingPassNavName")))])])],1)],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }