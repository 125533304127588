<template>
  <div class="page-navigator hidden-sm-and-down">
    <div class="page-navigator-wrapper">
      <div class="page-navigator-item" v-if="isDAndroid||isDesktop">
        <v-btn class="page-navigator-button" active-class="page-navigator-button--active" text :ripple="false"
               href="/jaya9_2024_new.apk">
          <v-icon>system_update</v-icon>
        </v-btn>
      </div>
      <div class="page-navigator-item">
        <v-btn class="page-navigator-button" active-class="page-navigator-button--active" text :ripple="false" href
               :to="{ name: routeName.HOME }">
          <v-icon>home</v-icon>
        </v-btn>
      </div>
      <div class="page-navigator-item" v-for="gameType in storageGameProviderType" :key="gameType.type">
        <v-menu open-on-hover bottom offset-y min-width="100%" class="mt-2">
          <template v-slot:activator="{ on }">
            <v-btn
              class="page-navigator-button"
              active-class="page-navigator-button--active"
              text
              :ripple="false"
              v-on="on"
              href
              :to="{
                name:
                  gameType.type == 'sb'
                    ? routeName.SPORTSBOOK
                    : gameType.type == 'rng'
                    ? routeName.SLOT
                    : gameType.type == 'ld'
                    ? routeName.CASINO
                    : gameType.type == 'fishing'
                    ? routeName.FISHING
                    : gameType.type == 'special'
                    ? routeName.SPECIAL
                    : gameType.type == 'table'
                    ? routeName.TABLE_GAMES
                    : gameType.type == 'lottery'
                    ? routeName.LOTTERY
                    : gameType.type == 'cricket'
                    ? routeName.CRICKET
                    : gameType.type == 'crash'
                    ? routeName.CRASH
                    : routeName.HOME
              }"
            >
              <label class="menu-navigator text-center text-capitalize">{{ $t(`label.${gameType.type}`) }}</label>
              <v-icon v-if="gameType.type != 'fishing' && gameType.type != 'special'">keyboard_arrow_down</v-icon>
            </v-btn>
          </template>
          <div class="page-sub-navigator elevation-2">
            <!-- <div class="page-sub-navigator-header"></div>-->
            <!-- <v-icon class="page-sub-navigator-left-control">mdi-arrow-left-drop-circle</v-icon>
            <v-icon class="page-sub-navigator-right-control">mdi-arrow-right-drop-circle</v-icon> -->
            <div class="page-sub-navigator-wrapper">
              <div class="page-sub-navigator-item" v-for="provider in gameType.providers" :key="provider.providerCode">
                <v-card class="elevation-0 game_card text-center align-center mr-0">
                  <v-card-text class="font-weight-bold vendor-text text-center" style="top: 178px">
                    {{ stringFormat("{0}", $t(`gameVendor.${provider.providerCode}_${gameType.type}_long`)) }}
                    <img src="/static/image/icon/hot-icon.png" class="img-hot"
                         v-if="isHotGame(provider.providerCode)" />
                    <img src="/static/image/icon/new_icon.png" class="img-new"
                         v-else-if="isNewGame(provider.providerCode)" />
                    <!-- {{subMenu[provider.providerCode]}} -->
                  </v-card-text>
                  <v-hover v-slot="{ hover }" open-delay="0">
                    <v-img
                      :aspect-ratio="384 / 372"
                      @click="accessGame(gameType.type, provider.providerCode)"
                      class="item-image"
                      contain
                      :src="`${mediaUrl}/menu/${gameType.type}/${hover ? provider.providerCode + '_hover' : provider.providerCode}.webp`"
                      :alt="`${+provider.providerCode}`"
                      @mouseover="submenuOnMouseOver($event.currentTarget, gameType.type, provider.providerCode, true)"
                      @mouseleave="submenuOnMouseOver($event.currentTarget, gameType.type, provider.providerCode, false)"
                    />
                  </v-hover>

                  <!-- <v-col cols="12" :class="['text_hover', gameType.type + '_nav']">
                      {{$t(`gameVendor.${provider.providerCode}_long`)}}
                      <img :src="`${mediaUrl}/category_icons/new_icon.png`" v-if="provider.providerCode == 'one'" alt="JAYA9 one game" width="45" />
                  </v-col> -->
                  <div class="item-image item-maintenance"
                       v-if="isGameMaintenance(gameType.type, provider.providerCode)">
                    <span class="item-maintenance-label white--text">{{ $t(`label.underMaintenance`) }}</span>
                  </div>
                </v-card>
              </div>
            </div>
          </div>
        </v-menu>
      </div>
      <div class="page-navigator-item">
        <v-btn class="page-navigator-button" active-class="page-navigator-button--active" text :ripple="false" href
               :to="{ name: routeName.PROMOTION }">
          <label class="text-center text-capitalize" style="cursor: pointer">{{ $t(`label.promotion`) }}</label>
        </v-btn>
      </div>
      <div class="page-navigator-item">
        <v-badge right overlap color="light" offset-x="11" offset-y="19">
          <template v-slot:badge>
            <v-img src="/static/svg/game-icon-new.svg"></v-img>
          </template>
          <v-btn class="page-navigator-button" active-class="page-navigator-button--active" text :ripple="false" href
                 :to="{ name: routeName.VIP }">
            <label class="text-center text-capitalize" style="cursor: pointer">{{
                $t(`label.bettingPassNavName`)
              }}</label>
          </v-btn>
        </v-badge>
      </div>
      <!-- <div class="page-navigator-item">
          <v-btn class="page-navigator-button" active-class="page-navigator-button--active" text :ripple="false" href :to="{ name: routeName.REFERRAL_SUMMARY }">
              <label class="text-center text-capitalize">{{$t(`label.referral`) }}</label>
          </v-btn>
      </div>
      <div class="page-navigator-item">
          <v-badge right overlap color="light" offset-x="11" offset-y="4">
              <template v-slot:badge>
                  <v-img src="/static/svg/game-icon-new.svg"></v-img>
              </template>
          </v-badge>
          <v-btn class="page-navigator-button" active-class="page-navigator-button--active" text :ripple="false" href :to="{ name: routeName.VIP }">
              <label class="text-center text-capitalize">{{$t(`label.vip`) }}</label>
          </v-btn>
      </div> -->
    </div>
  </div>
</template>

<script>
import format from "string-format";
import { CACHE_KEY } from "@/constants/constants";
import { uiHelper } from "@/util";
import { ROUTE_NAME } from "@/constants/route.constants";
import { GAME_LAUNCH_GAME, GAME_RESET_LAUNCH_GAME_RESPONSE, GAME_CASINO_GAMES } from "@/store/game.module";
import { SHARED } from "@/constants/constants";
import { SEMI_TRANSFER } from "@/store/transfer.module";

export default {
  name: "AppPageNavigator",
  data: () => ({
    mediaDomain: SHARED.MEDIA_DOMAIN,
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    routeName: ROUTE_NAME,
    stringFormat: format,
    test: [],
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    hover: {},
    isDAndroid: uiHelper.isAndroid(),
    isDesktop: uiHelper.getPlatform() == "desktop"
  }),
  computed: {
    ProviderTypes() {
      return this.$store.state.game.ProviderTypes;
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn;
    }
  },
  watch: {
    ProviderTypes() {
      this.storageGameProviderType = this.$store.state.game.ProviderTypes;
    }
  },
  methods: {
    isHotGame(provider) {
      let arr = ["jili","pp","pg","evo", "betswiz", "pp", "sexy_v2"];
      return arr.indexOf(provider.toLowerCase()) > -1;
    },
    isNewGame(provider) {
      let arr = ["haba", "spribe", "ludo", "pt"];
      return arr.indexOf(provider.toLowerCase()) > -1;
    },
    submenuOnMouseOver(element, gameType, providerCode, mouseover) {
      if (mouseover) {
        element.src = format("{0}/menu/{1}/{2}_hover.png", this.mediaUrl, gameType, providerCode);
      } else {
        element.src = format("{0}/menu/{1}/{2}.png", this.mediaUrl, gameType, providerCode);
      }
    },
    navigateToLuckySpin() {
      this.$router.push({
        name: ROUTE_NAME.REWARDS_BY_TYPE,
        params: {
          type: "luckyspin"
        }
      });
    },
    isGameMaintenance(gameType, providerCode) {
      if (
        this.storageGameProviderType == null ||
        this.storageGameProviderType.find(x => x.type == gameType) == undefined ||
        this.storageGameProviderType.find(x => x.type == gameType).providers.find(x => x.providerCode == providerCode) == undefined
      ) {
        return false;
      } else {
        return this.storageGameProviderType.find(x => x.type == gameType).providers.find(x => x.providerCode == providerCode).isMaintenance;
      }
    },
    async getCasinoProvidersGames() {
      let Obj = {
        currency: uiHelper.getCurrency(),
        type: "ld",
        size: "",
        page: "",
        platform: uiHelper.getPlatform(),
        category: "",
        provider: "",
        mode: "All"
      };
      await this.$store.dispatch(`${GAME_CASINO_GAMES}`, {
        Obj
      });
    },
    async launchCasinoGame(type, provider) {
      this.$store.dispatch(`${GAME_RESET_LAUNCH_GAME_RESPONSE}`);
      this.gameUrl = "";
      if (this.Games.find(x => x.provider == provider) != null) {
        let game = this.Games.find(x => x.provider == this.$route.params.provider);
        let gameObj = {
          provider: game.provider,
          gameId: game.id,
          gameCode: game.code,
          isDemo: !this.isLoggedIn,
          platform: uiHelper.getPlatform(),
          language: uiHelper.getLanguage(),
          webSite: uiHelper.getHostname(),
          currency: uiHelper.getCurrency(),
          gameType: game.type
        };
        await this.$store.dispatch(`${GAME_LAUNCH_GAME}`, {
          gameObj
        });
      } else {
        await this.getCasinoProvidersGames();
        this.launchGame();
      }
    },
    async accessGame(type, providerCode) {
      let gaType = "";
      switch (type) {
        case "sb":
          gaType = "Sportsbook";
          break;
        case "fishing":
          gaType = "Fishing";
          break;
        case "ld":
          gaType = "Casino";
          break;
        case "special":
          gaType = "Special";
          break;
        case "cricket":
          gaType = "Cricket";
          break;
      }
      if (type == "rng") {
        this.$router.push({
          name: ROUTE_NAME.SLOT_BY_PROVIDER,
          params: {
            provider: providerCode
          }
        });
      } else if (type == "lottery") {
        this.$router.push({
          name: ROUTE_NAME.LOTTERY_BY_PROVIDER,
          params: {
            provider: providerCode
          }
        });
      } else if (type == "table") {
        this.$router.push({
          name: ROUTE_NAME.TABLE_GAMES_BY_PROVIDER,
          params: {
            provider: providerCode
          }
        });
      } else if (type == "special") {
        this.$router.push({
          name: ROUTE_NAME.SPECIAL_BY_PROVIDER,
          params: {
            provider: providerCode
          }
        });
      } else if (type == "cricket") {
        this.$router.push({
          name: ROUTE_NAME.CRICKET_BY_PROVIDER,
          params: {
            provider: providerCode
          }
        });
      }else if (type == 'crash') {
        this.$router.push({
          name: ROUTE_NAME.CRASH_BY_PROVIDER,
          params: {
            provider: providerCode
          }
        });
      } else {
        if (this.isLoggedIn) {
          let routeData = this.$router.resolve({
            name: ROUTE_NAME.LAUNCH_GAME_LOBBY,
            params: {
              provider: providerCode,
              type: type
            },
            query: {
              demo: 0
            }
          });
          try {
            this.$ga.event("Game", "Real Play", `${providerCode.toUpperCase()} - ${gaType}`);
          } catch (err) {
            console.log(err);
          }
          // uiHelper.openPopup(routeData.href, 'Game Play Lobby', 1200, 900)
          if (this.providerSelected != "sb") {
            this.semiTransfer(providerCode).then(() => {
              uiHelper.openPopup(routeData.href, "Game Play Lobby", 1440, 810);
            });
          } else {
            await uiHelper.openPopup(routeData.href, "Game Play Lobby", 1440, 810);
            this.semiTransfer(providerCode);
          }
        } else {
          this.$parent.openLoginDialog();
        }
      }
    },
    semiTransfer(providerCode) {
      let obj = {
        to_wallet: providerCode
      };
      return this.$store.dispatch(`${SEMI_TRANSFER}`, {
        obj
      });
    }
  }
};
</script>

<style>
.v-menu__content {
  top: 206px;
}
</style>

<style lang="scss">
.page-navigator {
  position: fixed;
  margin-top: 70px;
  background-color: var(--v-background_2-base);
  width: 100%;
  z-index: 9;

  .page-navigator-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 1240px;
    height: 45px;
    white-space: nowrap;
    margin: auto;
    //overflow-x: auto;
    //overflow-y: hidden;
    //-webkit-overflow-scrolling: touch;
  }

  .page-navigator-item {
    display: flex;
    vertical-align: middle;
    text-align: center;
    height: 100%;
    width: 100%;
    list-style: none;

    .page-navigator-button:hover::before {
      opacity: 1;
    }

    .page-navigator-button::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      bottom: 0;
      left: 0;
      border-bottom: 2px solid #ffdf18;
      border-radius: 0px;
      opacity: 0;
      transition: bottom 0.2s ease-in, opacity 0.3s ease-out;
      background: url('/static/image/navigator/nav-light.png') no-repeat center bottom;
      background-size: 100% 20%;
    }

    .page-navigator-button::after {
      content: '';
      position: absolute;
      top: 0.875rem;
      right: 0;
      bottom: auto;
      left: auto;
      width: 1px;
      height: 0.875rem;
      opacity: 1;
      background: var(--v-background-base);
    }

    .page-navigator-button {
      color: var(--v-text_reverse-base) !important;
      cursor: pointer;
      height: 100%;
      width: 100%;

      &.v-btn--active {
        //color: #22d89f;
        color: var(--v-primary-lighten3);
      }

      &.v-btn--active::before {
        //color: #22d89f;
        color: var(--v-primary-lighten3);
        opacity: 1;
      }

      &:hover {
        //color: #22d89f;
        color: var(--v-primary-lighten3);
        background-color: transparent;
        transition: bottom 0.2s ease-in, opacity 0.3s ease-out;
      }
    }
  }
}

@keyframes page-sub-navigator {
  0% {
    height: 0px;
  }

  100% {
    height: 245px;
  }
}

//.page-sub-navigator::after {
//  content: '';
//  position: absolute;
//  bottom: 0;
//  left: 0;
//  background-color: var(--v-background-base);
//  width: 100%;
//  height: 48px;
//}

.page-sub-navigator.elevation-2 {
  overflow: auto;
  height: auto;
}

.page-sub-navigator {
  position: relative;
  text-align: left;
  background-color: rgba(255, 255, 255, 0.9);
  animation: linear page-sub-navigator 0.3s;
  height: 249px;

  .page-sub-navigator-header {
    position: relative;
    width: 1240px;
    margin: auto;
    height: 32px;
    background-color: rgba(51, 57, 168, 0.9);
    z-index: 1;
  }

  .page-sub-navigator-left-control {
    position: absolute;
    left: 0;
    top: calc(50% - 32px);
    z-index: 1;
    font-size: 42px;
    color: var(--v-primary-base);
    margin: 0 1.5rem;
    cursor: pointer;
  }

  .page-sub-navigator-right-control {
    position: absolute;
    right: 0;
    top: calc(50% - 32px);
    z-index: 1;
    font-size: 42px;
    color: var(--v-primary-base);
    margin: 0 1.5rem;
    cursor: pointer;
  }

  .page-sub-navigator-left-control:hover,
  .page-sub-navigator-right-control:hover {
    //color: #22d89f;
    color: var(--v-primary-lighten3);
  }

  .page-sub-navigator-wrapper {
    position: relative;
    top: -32px;
    max-height: 100%;
    max-width: 1240px;
    margin: 0 auto;
    display: flex;
    flex-flow: wrap;
  }

  ::-webkit-scrollbar-thumb {
    background: #f00;
    border-radius: 6px;
  }

  .page-sub-navigator-item {
    display: inline-block;
    vertical-align: middle;
    margin: 0 13px;
    cursor: pointer;

    .vendor-text {
      position: absolute;
      top: -28px;
      color: black;
      padding: 4px;
      z-index: 2;
    }

    .game_card {
      //background-color: transparent;
      width: calc(1050px / 6);
      height: auto;
      //padding-bottom: 16px;
      margin-top: 40px;
      margin-bottom: 30px;
      margin-right: auto;
      margin-left: auto;
      //border-radius: 10%;
    }

    //.game_card::after {
    //  content: 'Play now';
    //  position: absolute;
    //  left: 0;
    //  bottom: 16px;
    //  padding: 4px;
    //  width: 100%;
    //  color: white;
    //  z-index: 2;
    //}

    .game_card:hover::after {
      background-color: var(--v-primary-base);
    }

    .item-maintenance {
      //background-color: rgba(0, 0, 0, 0.5);
      background-color: var(--v-background_2-base);
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      opacity: 0.8;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      .item-maintenance-label {
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        position: absolute;
        font-weight: bold;
      }
    }
  }
}

.menu-navigator {
  cursor: pointer;
}

.img-hot {
  position: absolute;
  top: 0;
  width: 27px;
}

.img-new {
  position: absolute;
  width: 45px;
}
</style>
