import { bonusService } from '@/services'
import { SHARED_LOADING, SHARED_UNLOADING } from '@/store/shared.module'
import { locale } from '@/util'

//define module name
const MODULE_NAME = 'bonus/'
//define actions name
const A_CATEGORIES = 'getCategories'
const A_ANON_LIST = 'getAnonBonusList'
const A_MANUAL_BONUS_LIST = 'getManualBonusList'
const A_LIST = 'getMemberAvailableBonusList'
const A_HISTORY = 'getBonusHistory'
const A_GET_GRAB_COIN_DETAILS = 'getGrabCoinDetails'
const A_GRAB_COIN_NOW = 'grabCoinNow'
const A_RESET_GRAB_COIN_NOW = 'resetGrabCoinNow'
const A_GET_REWARD_COIN_LOGS = 'getRewardCoinLogs'
const A_GET_VIP_REWARDS = 'getVipRewards'
const A_GET_REBATE_HISTORY = 'getRebateHistory'
const A_CLAIM_REBATE = 'claimRebate'

//define dispatch action
export const BONUS_CATEGORIES = MODULE_NAME + A_CATEGORIES
export const BONUS_ANON_LIST = MODULE_NAME + A_ANON_LIST
export const BONUS_MANUAL_BONUS_LIST = MODULE_NAME + A_MANUAL_BONUS_LIST
export const BONUS_LIST = MODULE_NAME + A_LIST
export const BONUS_HISTORY = MODULE_NAME + A_HISTORY
export const GRAB_COIN_NOW = MODULE_NAME + A_GRAB_COIN_NOW
export const RESET_GRAB_COIN = MODULE_NAME + A_RESET_GRAB_COIN_NOW
export const GRAB_COIN_SETTING = MODULE_NAME + A_GET_GRAB_COIN_DETAILS
export const REWARD_COIN_LOGS = MODULE_NAME + A_GET_REWARD_COIN_LOGS
export const VIP_REWARDS_SUMMARY = MODULE_NAME + A_GET_VIP_REWARDS
export const REBATE_HISTORY = MODULE_NAME + A_GET_REBATE_HISTORY
export const CLAIM_REBATE = MODULE_NAME + A_CLAIM_REBATE

//define mutations name
const M_CATEGORIES = 'setCategories'
const M_ANON_LIST = 'setAnonBonusList'
const M_MANUAL_BONUS_LIST = 'setManualBonusList'
const M_LIST = 'setMemberAvailableBonusList'
const M_HISTORY = 'setBonusHistory'
const M_SET_GRAB_COIN_SETTING = 'setGrabCoinSetting'
const M_SET_REWARD_COIN_LOGS = 'setRewardCoinLogs'
const M_SET_GRAB_COIN_NOW = 'setGrabCoinNow'
const M_RESET_GRAB_COIN_NOW = 'resetGrabCoinNow'
const M_SET_VIP_REWARDS = 'setVipRewards'
const M_SET_REBATE_HISTORY = 'setRebateHistory'
const M_SET_CLAIM_REBATE_RESULT = 'setRebateResult'

const A_BONUS_OVERALL_STATUS = 'getBonusOverallStatus'
const A_CLAIM_BONUS = 'claimBonus'
const A_RESET_CLAIM_BONUS_STORE = 'resetClaimBonusStore'
const A_APPLY_BONUS = 'applyBonus'
const A_RESET_APPLY_BONUS_RESPONSE_STORE = 'resetApplyBonusResponseStore'
const A_APPLY_VOUCHER_CHECK = 'checkVoucherCode'
const A_APPLY_VOUCHER_CHECK_RESET = 'checkVoucherCodeReset'
const A_APPLY_VOUCHER_BONUS = 'applyVoucherCode'
const A_APPLY_VOUCHER_RESET = 'applyVoucherCodeReset'
const A_CHECK_VIP_BONUS = 'checkVipBonusAvailable'
const A_APPLY_VIP_BONUS = 'applyVipBonusAvailable'
const A_RESET_VIP_BONUS = 'resetVipBonusAvailable'
const A_VIP_CLAIM_REWARD = 'claimVipReward'
const A_VIP_CLAIM_RESET = 'resetVipClaim'

export const BONUS_OVERALL_STATUS = MODULE_NAME + A_BONUS_OVERALL_STATUS
export const CLAIM_BONUS = MODULE_NAME + A_CLAIM_BONUS
export const RESET_CLAIM_BONUS_STORE = MODULE_NAME + A_RESET_CLAIM_BONUS_STORE
export const BONUS_APPLY_BONUS = MODULE_NAME + A_APPLY_BONUS
export const BONUS_RESET_APPLY_BONUS_RESPONSE_STORE = MODULE_NAME + A_RESET_APPLY_BONUS_RESPONSE_STORE
export const VOUCHER_BONUS_CHECK = MODULE_NAME + A_APPLY_VOUCHER_CHECK
export const VOUCHER_BONUS_CHECK_RESET = MODULE_NAME + A_APPLY_VOUCHER_CHECK_RESET
export const VOUCHER_BONUS_APPLY = MODULE_NAME + A_APPLY_VOUCHER_BONUS
export const VOUCHER_BONUS_APPLY_RESET = MODULE_NAME + A_APPLY_VOUCHER_RESET
export const VIP_BONUS_CHECK = MODULE_NAME + A_CHECK_VIP_BONUS
export const VIP_BONUS_APPLY = MODULE_NAME + A_APPLY_VIP_BONUS
export const VIP_BONUS_RESET = MODULE_NAME + A_RESET_VIP_BONUS
export const VIP_CLAIM_REWARD = MODULE_NAME + A_VIP_CLAIM_REWARD
export const VIP_RESET_CLAIM = MODULE_NAME + A_VIP_CLAIM_RESET

const M_FAILURE = 'setFailure'
const M_OVERALL_STATUS = 'setBonusOverallStatus'
const M_CLAIM_BONUS = 'claimBonus'
const M_RESET_CLAIM_BONUS_STORE = 'resetClaimBonusStoreComplete'
const M_APPLY_BONUS = 'setApplyBonus'
const M_RESET_APPLY_BONUS_RESPONSE_STORE = 'setResetApplyBonusResponseStore'
const M_SET_VOUCHER_CHECK_RESULT = 'setCheckVoucherResult'
const M_RESET_VOUCHER_CHECK_RESULT = 'resetCheckVoucherResult'
const M_SET_VOUCHER_APPLY_RESULT = 'setApplyVoucherResult'
const M_SET_VOUCHER_APPLY_RESET = 'setApplyVoucherReset'
const M_SET_VIP_BONUS_CHECK_RESULT = 'setCheckVipBonusCheck'
const M_SET_VIP_BONUS_APPLY_RESULT = 'setApplyVipBonusCheck'
const M_SET_VIP_BONUS_RESET = 'resetVipBonus'
const M_SET_VIP_PROGRESS_CLAIM_RESULT = 'setVipProgressClaimResult'
const M_RESET_VIP_CLAIM = 'resetVipClaim'

//init app state
const state = {
  grabCoin: {
    complete: false,
    success: false,
    code: 0,
    error: '',
    data: 0
  },
  rewardCoinsLog: [],
  grabCoinSettings: {
    grab_name: '',
    grab_description: '',
    status: 1,
    start_date: '',
    end_date: '',
    icon_one: '',
    icon_two: '',
    title_img: '',
    home_gif: '',
    prize_probability: [],
    is_recurring_chance: 0,
    min_deposit: '',
    created_at: '',
    updated_at: ''
  },
  categories: [],
  bonusAnonList: [],
  manualBonusList: [],
  bonusList: [],
  historyList: [],

  hotBonusList: [],
  sideBonusList: [],

  overallStatusList: [],
  pagination: {
    pageNumber: 0,
    pageSize: 0,
    lastPage: 0,
    totalItems: 0
  },
  claimBonusResponse: {
    action: 'claimBonus',
    code: 0,
    success: false,
    complete: false
  },
  applyBonusResponse: {},
  errorMsg: '',
  checkVoucherResponse: {
    action: 'checkVoucher',
    complete: false,
    success: false,
    code: 0,
    data: {
      bonusIsPercentage: '',
      bonusPercentage: 0,
      bonusGivenAmount: 0,
      bonusTargetRollover: 0
    }
  },
  applyVoucherResponse: {
    action: 'claimVoucher',
    complete: false,
    success: false,
    code: 0
  },
  vipBonusCheckResult: {
    complete: false,
    success: false,
    data: {
      available: false,
      bonusId: 0
    }
  },
  vipBonusApplyResult: {
    complete: false,
    success: false,
    data: {
      ref_id: '',
      bonus_given_amount: ''
    }
  },
  vipProgressClaimResult: {
    complete: false,
    success: false,
    data: {
      ref_id: '',
      bonus_given_amount: ''
    }
  },
  vipRewardsSummary: [
    {
      level: 0,
      rewards: 300,
      requirements: 0
    }
  ],
  vipRewardsLoadResult: {
    complete: false,
    success: false
  },
  rebateHistory: {
    data: [],
    pagination: {},
    totalRebate: {}
  },
  bonusHistory: {
    data: [],
    pagination: {}
  },
  claimRebateResult: {
    action: 'claimRebate',
    complete: false,
    success: false,
    code: 0
  }
}

//to retrieve data from store state (called by component)
const getters = {}

//to perform some actions related with updating state (called by component)
const actions = {
  [A_GRAB_COIN_NOW]({ dispatch, commit }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    bonusService.grabCoinNow().then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_GRAB_COIN_NOW, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_GRAB_COIN_NOW, { result })
      }
    )
  },
  [A_GET_REWARD_COIN_LOGS]({ dispatch, commit }, { filter }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    bonusService.getCoinsLogs(filter).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_REWARD_COIN_LOGS, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_REWARD_COIN_LOGS, { result })
      }
    )
  },
  [A_RESET_GRAB_COIN_NOW]({ commit }) {
    commit(M_RESET_GRAB_COIN_NOW)
  },
  [A_GET_GRAB_COIN_DETAILS]({ dispatch, commit }, { filter }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    bonusService.getRewardCoins(filter).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_GRAB_COIN_SETTING, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_GRAB_COIN_SETTING, { result })
      }
    )
  },
  [A_CATEGORIES]({ commit }) {
    bonusService.getCategory().then(
      data => {
        let result = data
        commit(M_CATEGORIES, { result })
      },
      error => {
        let result = error
        commit(M_CATEGORIES, { result })
      }
    )
  },
  async [A_ANON_LIST]({ dispatch, commit }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    await bonusService.getAnonBonusList().then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_ANON_LIST, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_ANON_LIST, { result })
      }
    )
  },
  async [A_MANUAL_BONUS_LIST]({ dispatch, commit }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    await bonusService.getManualBonusList().then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_MANUAL_BONUS_LIST, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_MANUAL_BONUS_LIST, { result })
      }
    )
  },
  [A_LIST]({ dispatch, commit }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    bonusService.getMemberAvailableBonusList().then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_LIST, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_LIST, { result })
      }
    )
  },
  [A_HISTORY]({ dispatch, commit }, { bonusHistoryObj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    bonusService.getBonusHistory(bonusHistoryObj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_HISTORY, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_HISTORY, { result })
      }
    )
  },

  [A_RESET_CLAIM_BONUS_STORE]({ commit }) {
    commit(M_RESET_CLAIM_BONUS_STORE)
  },
  [A_CLAIM_BONUS]({ dispatch, commit }, { id }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    bonusService.claimBonus(id).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_CLAIM_BONUS, { result })
      },
      error => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_FAILURE, error)
      }
    )
  },
  [A_BONUS_OVERALL_STATUS]({ dispatch, commit }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    bonusService.getOverallBonusStatus().then(
      data => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_OVERALL_STATUS, data)
      },
      error => {
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_FAILURE, error)
      }
    )
  },

  [A_APPLY_BONUS]({ dispatch, commit }, { applyBonusObj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    bonusService.applyBonus(applyBonusObj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_APPLY_BONUS, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_APPLY_BONUS, { result })
      }
    )
  },
  [A_RESET_APPLY_BONUS_RESPONSE_STORE]({ commit }) {
    commit(M_RESET_APPLY_BONUS_RESPONSE_STORE)
  },
  [A_APPLY_VOUCHER_CHECK]({ dispatch, commit }, { voucherObj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    bonusService.checkVoucherBonus(voucherObj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_VOUCHER_CHECK_RESULT, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_VOUCHER_CHECK_RESULT, { result })
      }
    )
  },
  [A_APPLY_VOUCHER_BONUS]({ dispatch, commit }, { voucherObj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    bonusService.applyVoucherBonus(voucherObj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_VOUCHER_APPLY_RESULT, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_VOUCHER_APPLY_RESULT, { result })
      }
    )
  },
  [A_APPLY_VOUCHER_RESET]({ commit }) {
    commit(M_SET_VOUCHER_APPLY_RESET)
  },
  [A_CHECK_VIP_BONUS]({ dispatch, commit }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    bonusService.checkVipBonus().then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_VIP_BONUS_CHECK_RESULT, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_VIP_BONUS_CHECK_RESULT, { result })
      }
    )
  },
  [A_VIP_CLAIM_REWARD]({ dispatch, commit }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    bonusService.claimProgressReward().then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_VIP_PROGRESS_CLAIM_RESULT, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_VIP_PROGRESS_CLAIM_RESULT, { result })
      }
    )
  },
  [A_VIP_CLAIM_RESET]({ dispatch, commit }) {
    commit(M_RESET_VIP_CLAIM)
  },
  [A_APPLY_VIP_BONUS]({ dispatch, commit }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    bonusService.applyVipBonus().then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_VIP_BONUS_APPLY_RESULT, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_VIP_BONUS_APPLY_RESULT, { result })
      }
    )
  },
  [A_RESET_VIP_BONUS]({ commit }) {
    commit(M_SET_VIP_BONUS_RESET)
  },
  [A_APPLY_VOUCHER_CHECK_RESET]({ commit }) {
    commit(M_RESET_VOUCHER_CHECK_RESULT)
  },
  [A_GET_VIP_REWARDS]({ commit, dispatch }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    bonusService.getVipSummary().then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_VIP_REWARDS, { result })
      },
      error => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_VIP_REWARDS, { result })
      }
    )
  },
  [A_GET_REBATE_HISTORY]({ dispatch, commit }, { obj }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    bonusService.getRebateHistory(obj).then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_REBATE_HISTORY, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_REBATE_HISTORY, { result })
      }
    )
  },
  [A_CLAIM_REBATE]({ dispatch, commit }) {
    dispatch(`${SHARED_LOADING}`, {}, { root: true })
    bonusService.claimRebate().then(
      data => {
        let result = data
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_CLAIM_REBATE_RESULT, { result })
      },
      error => {
        let result = error
        dispatch(`${SHARED_UNLOADING}`, {}, { root: true })
        commit(M_SET_CLAIM_REBATE_RESULT, { result })
      }
    )
  }
}

//to update state values (called by actions)
const mutations = {
  [M_SET_GRAB_COIN_NOW](state, { result }) {
    state.grabCoin = {
      complete: true,
      success: result.success,
      code: result.code,
      error: !result.success ? result.error : '',
      data: result.success ? result.data : 0
    }
  },
  [M_RESET_GRAB_COIN_NOW](state) {
    state.grabCoin = {
      complete: false,
      success: false,
      code: 0,
      error: '',
      data: 0
    }
  },
  [M_SET_REWARD_COIN_LOGS](state, { result }) {
    state.rewardCoinsLog = result.data
  },
  [M_SET_GRAB_COIN_SETTING](state, { result }) {
    let d = result.data
    if (d != null) {
      state.grabCoinSettings = {
        grab_name: d.grab_name,
        grab_description: d.grab_description,
        status: d.status,
        start_date: d.start_date,
        end_date: d.end_date,
        icon_one: d.icon_one,
        icon_two: d.icon_two,
        title_img: d.title_img,
        home_gif: d.home_gif,
        min_deposit: d.min_deposit,
        created_at: d.created_at,
        updated_at: d.updated_at
      }
    }
  },
  [M_CATEGORIES](state, { result }) {
    let p = []
    if (result.success) {
      p = result.data
    }
    state.categories = p
  },
  [M_ANON_LIST](state, { result }) {
    let p = []
    if (result.success) {
      let d = result.data
      state.bonusAnonList = []

      //process BonusList

      d.forEach(bonus => {
        let bonusLocale = []

        bonus.localization.forEach(language => {
          for (let i = 0; i < Object.keys(language).length; i++) {
            try {
              bonusLocale.push({
                language: Object.keys(language)[i],
                title: Object.values(language)[i],
                desc: bonus.desc_localization.length > 0 ? Object.values(bonus.desc_localization[0])[i] : ''
              })
            } catch (e) {
              bonusLocale.push({
                language: Object.keys(language)[i],
                title: Object.values(language)[i],
                desc: ''
              })
            }
          }
        })

        p.push({
          id: bonus.id,
          name: bonus.name,
          description: bonus.description,
          locale: bonusLocale,
          mode: bonus.bonus_mode,
          currency: bonus.currency,
          isPin: bonus.is_pinable,
          startDate: bonus.start_time,
          endDate: bonus.end_time,
          categories: bonus.categories,
          providers: bonus.providers,
          memberGroup: bonus.member_group,
          bonusMaxCap: bonus.max_cap_amount
        })
      })
    }
    state.bonusAnonList = p
  },
  [M_MANUAL_BONUS_LIST](state, { result }) {
    let p = []
    if (result.length > 0) {
      let d = result
      state.manualBonusList = []

      //process BonusList
      d.forEach(bonus => {
        if (new Date(bonus.endDate) >= new Date()) {
          p.push({
            id: bonus.id,
            name: bonus.name,
            description: bonus.description,
            locale: bonus.locale,
            mode: bonus.mode,
            currency: bonus.currency,
            isPin: bonus.isPin,
            startDate: bonus.startDate,
            endDate: bonus.endDate,
            categories: bonus.categories,
            providers: bonus.providers,
            memberGroup: bonus.member_group,
            bonusMaxCap: bonus.maximum_amount
          })
        }
      })
    }
    state.manualBonusList = p
  },
  [M_LIST](state, { result }) {
    let p = []
    if (result.success) {
      let d = result.data

      //process BonusList

      d.forEach(bonus => {
        let bonusLocale = []

        bonus.localization.forEach(language => {
          for (let i = 0; i < Object.keys(language).length; i++) {
            try {
              bonusLocale.push({
                language: Object.keys(language)[i],
                title: Object.values(language)[i],
                desc: bonus.desc_localization.length > 0 ? Object.values(bonus.desc_localization[0])[i] : ''
              })
            } catch (e) {
              bonusLocale.push({
                language: Object.keys(language)[i],
                title: Object.values(language)[i],
                desc: ''
              })
            }
          }
        })

        p.push({
          id: bonus.id,
          value: bonus.id,
          name: bonus.name,
          description: bonus.description,
          locale: bonusLocale,
          mode: bonus.bonus_mode,
          currency: bonus.currency,
          isPin: bonus.is_pinable,
          startDate: bonus.start_time,
          endDate: bonus.end_time,
          categories: bonus.categories.length > 0 ? bonus.categories : ['SPECIAL'],
          providers: bonus.providers,
          memberGroup: bonus.member_group,
          bonusMaxCap: bonus.max_cap_amount,
          maxAmount: bonus.maximum_amount,
          formula: bonus.formula,
          useReleaseTiers: bonus.use_release_tiers,
          percentGive: bonus.percent_give,
          amountGive: bonus.amount_give,
          turnoverMultiplier: bonus.turnover_multiplier,
          depositAmount: bonus.deposit_amount
        })
      })
    }

    state.bonusList = p
  },
  /*[M_HISTORY](state, {result}) {
        let p = []
        if (result.success) {
            let d = result.data

            state.pagination = {
                pageNumber: result.pagination.current_page,
                pageSize: Number(result.pagination.per_page),
                lastPage: result.pagination.last_page,
                totalItems: result.pagination.total
            }

            d.forEach(bonus => {
                let bonusLocale = []

                bonus.bonus.localization.forEach(language => {
                    for (let i = 0; i < Object.keys(language).length; i++) {
                        try {
                            bonusLocale.push({
                                language: Object.keys(language)[i],
                                title: Object.values(language)[i],
                                desc: bonus.desc_localization.length > 0 ? Object.values(bonus.desc_localization[0])[i] : ''
                            })
                        } catch (e) {
                            bonusLocale.push({
                                language: Object.keys(language)[i],
                                title: Object.values(language)[i],
                                desc: ''
                            })
                        }
                    }
                })

                let bonusTotalProgress = 0

                bonus.bonus_progress.forEach(progress => {
                    bonusTotalProgress += progress.current_progress / progress.total_progress
                })

                bonusTotalProgress = bonusTotalProgress / bonus.bonus_progress.length

                p.push({
                    id: bonus.bonus.id,
                    applicantId: bonus.id,
                    value: bonus.bonus.id,
                    name: bonus.bonus.name,
                    description: bonus.description,
                    locale: bonusLocale,
                    mode: bonus.bonus.bonus_mode,
                    currency: bonus.bonus.currency,
                    isPin: bonus.bonus.is_pinable,
                    startDate: bonus.bonus.start_time,
                    endDate: bonus.bonus.end_time,
                    categories: bonus.bonus.categories.length > 0 ? bonus.categories : ['SPECIAL'],
                    providers: bonus.bonus.providers,
                    memberGroup: bonus.bonus.member_group,
                    bonusMaxCap: bonus.bonus.max_cap_amount,
                    bonusGivenAmount: bonus.bonus_given_amount,
                    bonusProgress: bonus.bonus_progress,
                    bonusTotalProgress: bonusTotalProgress,
                    bonusApplyDate: bonus.created_at,
                    bonusExpireDate: bonus.serving_expired_date,
                    refId: bonus.ref_id,
                    status: bonus.status

                })
            })
        }
        state.historyList = p
    },*/

  [M_RESET_CLAIM_BONUS_STORE](state) {
    state.claimBonusResponse = {
      action: 'claimBonus',
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_CLAIM_BONUS](state, { result }) {
    state.claimBonusResponse = {
      action: 'claimBonus',
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_OVERALL_STATUS](state, data) {
    let d = data.data
    let processedList = []
    for (let i = 0; i < d.length; i++) {
      processedList.push(locale.getMessage(`enumBonusStatus.${d[i]}`))
    }

    state.overallStatusList = processedList
  },

  [M_APPLY_BONUS](state, { result }) {
    if (result.success) {
      let d = result.data
      state.applyBonusResponse = {
        referenceId: d.ref_id,
        status: d.status,
        complete: true,
        success: result.success,
        code: result.code,
        error: result.message
      }
    } else {
      state.applyBonusResponse = {
        complete: true,
        success: result.success,
        code: result.code,
        error: result.message
      }
    }
  },
  [M_RESET_APPLY_BONUS_RESPONSE_STORE](state) {
    state.applyBonusResponse = {
      complete: false,
      success: false,
      code: 0,
      error: ''
    }
  },
  [M_FAILURE](state, error) {
    state.errorMsg = error
  },
  [M_SET_VOUCHER_CHECK_RESULT](state, { result }) {
    state.checkVoucherResponse.complete = true
    state.checkVoucherResponse.success = result.success
    state.checkVoucherResponse.code = result.code
    if (result.success) {
      state.checkVoucherResponse.data.bonusIsPercentage = result.data.bonus_is_percentage
      state.checkVoucherResponse.data.bonusPercentage = result.data.bonus_percentage_amount
      state.checkVoucherResponse.data.bonusGivenAmount = result.data.bonus_given_amount
      state.checkVoucherResponse.data.bonusTargetRollover = result.data.bonus_target_rollover
    }
  },
  [M_SET_VOUCHER_APPLY_RESULT](state, { result }) {
    state.applyVoucherResponse.complete = true
    state.applyVoucherResponse.success = result.success
    state.applyVoucherResponse.code = result.code
  },
  [M_SET_VOUCHER_APPLY_RESET](state) {
    state.applyVoucherResponse.complete = false
    state.applyVoucherResponse.success = false
    state.checkVoucherResponse.complete = false
    state.checkVoucherResponse.success = false
  },
  [M_SET_VIP_BONUS_CHECK_RESULT](state, { result }) {
    state.vipBonusCheckResult.complete = true
    state.vipBonusCheckResult.success = result.success
    if (result.success) {
      state.vipBonusCheckResult.data.available = result.data.available
      state.vipBonusCheckResult.data.bonusId = result.data.bonus_id
    }
  },
  [M_SET_VIP_BONUS_APPLY_RESULT](state, { result }) {
    state.vipBonusApplyResult.complete = true
    state.vipBonusApplyResult.success = result.success
    state.vipBonusApplyResult.data.fund_in_amount = result.data.fund_in_amount
    state.vipBonusApplyResult.data.bonus_given_amount = result.data.bonus_given_amount
  },
  [M_SET_VIP_BONUS_RESET](state) {
    state.vipBonusCheckResult.complete = false
    state.vipBonusCheckResult.success = false
    state.vipBonusApplyResult.complete = false
    state.vipBonusApplyResult.success = false
  },
  [M_RESET_VOUCHER_CHECK_RESULT](state) {
    state.checkVoucherResponse.complete = false
    state.checkVoucherResponse.success = false
    state.checkVoucherResponse.code = 0
    state.checkVoucherResponse.data = {}
  },
  [M_SET_VIP_PROGRESS_CLAIM_RESULT](state, { result }) {
    state.vipProgressClaimResult.complete = true
    state.vipProgressClaimResult.success = result.success
    state.vipProgressClaimResult.code = result.code
    state.vipProgressClaimResult.data = result.data
  },
  [M_RESET_VIP_CLAIM](state) {
    state.vipProgressClaimResult.complete = false
    state.vipProgressClaimResult.success = false
    state.vipProgressClaimResult.code = 0
    state.vipProgressClaimResult.data = {}
  },
  [M_SET_VIP_REWARDS](state, { result }) {
    state.vipRewardsLoadResult.complete = true
    state.vipRewardsLoadResult.success = result.success
    if (!result.success) {
      return
    }
    const returnedData = result.data
    let giftIndex = [95, 96, 97, 98, 99]
    state.vipRewardsSummary = returnedData.map((r, index) => {
      let resulting_reward = ''
      switch (r.reward_type) {
        case 'COINS':
          resulting_reward = r.reward_upgrade.coins
          break
        case 'BONUS':
          resulting_reward = r.reward_bonus.maximum_amount + '%'
          break
        case 'GIFT':
          resulting_reward = r.reward_gift.name
          break
      }
      let overGift
      if (giftIndex.indexOf(index) != -1) {
        /*overwrite gift props*/

        switch (index) {
          case 95:
            overGift = JSON.parse(
              '{"level_sequence":96,"name":"32\' LED TV", "imgUrl":"https://s3.ap-southeast-1.amazonaws.com/media-dev.ezgame4u.com/images/jaya9/vip/jayarank/samsung-55-smarttv.png"}'
            )
            break
          case 96:
            overGift = JSON.parse('{"level_sequence":97,"name":"Apple IPAD", "imgUrl":"https://s3.ap-southeast-1.amazonaws.com/media-dev.ezgame4u.com/images/jaya9/vip/jayarank/apple-ipad.png"}')
            break
          case 97:
            overGift = JSON.parse(
              '{"level_sequence":98,"name":"60\' SMART TV", "imgUrl":"https://s3.ap-southeast-1.amazonaws.com/media-dev.ezgame4u.com/images/jaya9/vip/jayarank/samsung-55-smarttv.png"}'
            )
            break
          case 98:
            overGift = JSON.parse(
              '{"level_sequence":99,"name":"IPHONE 16 PRO MAX", "imgUrl":"https://jiliwin.9terawolf.com/images/jaya9/vip/jayarank/iphone16promax.png"}'
            )
            break
          case 99:
            overGift = JSON.parse(
              '{"level_sequence":100,"name":"APPLE Macbook PRO", "imgUrl":"https://s3.ap-southeast-1.amazonaws.com/media-dev.ezgame4u.com/images/jaya9/vip/jayarank/macbook-pro.png"}'
            )
            break
        }
      }

      return {
        level: r.level_sequence,
        reward_type: r.reward_type,
        reward_gift: overGift != undefined ? overGift : '',
        reward_bonus: r.reward_bonus,
        reward_upgrade: r.reward_upgrade,
        text: resulting_reward,
        requirements: r.required_deposit_amount
      }
    })
  },
  [M_SET_REBATE_HISTORY](state, { result }) {
    if (result.success) {
      state.rebateHistory.data = result.data
      state.rebateHistory.pagination = result.pagination
      state.rebateHistory.totalRebate = result.total
    } else {
      state.rebateHistory.data = []
      state.rebateHistory.pagination = {}
      state.rebateHistory.totalRebate = {}
    }
  },
  [M_HISTORY](state, { result }) {
    if (result.success) {
      state.bonusHistory.data = result.data
      state.bonusHistory.pagination = result.pagination
    } else {
      state.bonusHistory.data = []
      state.bonusHistory.pagination = {}
    }
  },
  [M_SET_CLAIM_REBATE_RESULT](state, { result }) {
    state.claimRebateResult.complete = true
    state.claimRebateResult.success = result.success
    state.claimRebateResult.code = result.code
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
