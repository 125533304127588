<template>
  <div>
    <v-row no-gutters v-if="!isIos && $vuetify.breakpoint.smAndDown && showDownloadAPK" class="download-bar">
      <v-icon @click="hideDownloadBar()" class="mr-2">mdi-close</v-icon>
      <img class="mr-2" width="32" height="32" src="/static/image/layout/download-app.png" alt="download" />
      <v-col class="mr-1">
        <label>{{ $t(`label.downloadApp`) }}</label>
      </v-col>
      <app-button title="DOWNLOAD" :action="downloadAPK" :customClass="`download-bar-button subtitle-1`"></app-button>
    </v-row>
    <v-toolbar
      dense
      :class="`full-width header-top elevation-3 ${!isIos && $vuetify.breakpoint.smAndDown && showDownloadAPK ? 'download-bar-height' : ''}`"
      :style="$vuetify.breakpoint.mdAndUp ? 'height: 70px; ' : 'height: 45px'"
    >
      <v-row v-if="$vuetify.breakpoint.smAndDown" class="mobile">
        <v-col class="d-flex flex-grow-0 align-center" cols="2">
          <v-app-bar-nav-icon color="white" @click.stop="drawer = !drawer" height="18" width="22"></v-app-bar-nav-icon>
        </v-col>

        <v-col class="d-flex justify-space-around pt-2">
          <router-link to="/" class="d-flex justify-center align-center">
            <img width="100px" src="/static/image/home/logo3.png" alt="JAYA9 cricket exchange" />
          </router-link>
        </v-col>

        <!-- <app-login-embedded v-if="!isLoggedIn" :openRegisterDialog="this.openRegisterDialog" :openLoginDialog="this.openLoginDialog" :openLanguageDialog="this.openLanguageDialog"></app-login-embedded> -->

        <v-col class="d-flex align-center mobile-header-rightBtns" cols="2">
          <v-btn icon color="white" v-if="isLoggedIn && this.isDAndroid" :plain="true" @click="downloadAPK">
            <v-img src="/static/image/home/apkDownload.png" width="32" height="28" :contain="true"></v-img>
          </v-btn>
          <v-btn icon :to="{ name: routeName.INBOX }" color="white" v-if="isLoggedIn" :plain="true">
            <v-badge dot bottom left offset-x="5" offset-y="8" :color="hasUnreadInboxMsg ? 'red' : 'transparent'">
              <v-img src="/static/svg/mail.svg" width="32" height="23" :contain="true"></v-img>
            </v-badge>
          </v-btn>
        </v-col>
      </v-row>

      <v-row no-gutters class="hidden-sm-and-down header-top-desktop">
        <v-row class="col" no-gutters>
          <v-row class="self-align" no-gutters justify="start">
            <router-link to="/" class="d-flex justiy-center">
              <img height="48" s src="/static/image/home/logo3.png" alt="JAYA9 exchange" />
            </router-link>
          </v-row>

          <v-row no-gutters justify="end" class="header-column">
            <div v-if="isLoggedIn" class="is-login-header">
              <!-- <v-btn icon :to="{ name: routeName.INBOX }" class="mr-4">
                  <v-badge bottom left offset-x="6" offset-y="12" :color="hasUnreadInboxMsg ? 'red' : 'transparent'">
                      <v-icon size="48" class="color-primary">
                          email
                      </v-icon>
                  </v-badge>
              </v-btn> -->
              <label>{{ $t(`label.mainWallet`) }}</label>
              <label class="text-capitalize balance" v-if="notLoading">
                <span v-if="showBalance">{{ memberWallet.totalBalance | currency(currentCurrency) | maskNumber }}</span>
                <span v-else>{{ memberWallet.totalBalance | currency(currentCurrency) }}</span>
              </label>
              <v-progress-circular indeterminate v-if="!notLoading" color="amber" :width="3" :size="24"></v-progress-circular>
              <v-icon size="20" color="var(--v-secondary-base)" @click="getMemberWallet()" class="refresh-balance">
                mdi-refresh
              </v-icon>
              <button-primary :action="() => goToDepositPage()" :title="$t('label.deposit')"></button-primary>
              <div @click="goToProfilePage">
                <label class="invisible-button">{{ $t(`label.memberCenter`) }}</label>
              </div>
              <div @click="logout">
                <label class="invisible-button">{{ $t(`label.logout`) }}</label>
              </div>
            </div>
            <app-login-embedded
              v-if="!isLoggedIn"
              :openRegisterDialog="this.openRegisterDialog"
              :openLoginDialog="this.openLoginDialog"
              :openLanguageDialog="this.openLanguageDialog"
            ></app-login-embedded>

            <v-btn @click="openLanguageDialog()" class="text-capitalize align-self-center white--text invisible-button" :plain="true">
              <v-avatar size="24" class="language-button">
                <img :src="`/static/image/country/${currentCurrency}.svg`" alt="JAYA9 exchange" />
              </v-avatar>
              <label>{{ languageSelected }} {{ $t(`language.${currentLanguage}`) }}</label>
            </v-btn>
          </v-row>
        </v-row>
      </v-row>
    </v-toolbar>

    <v-navigation-drawer class="side-navigation-drawer" v-model="drawer" fixed left temporary width="60%">
      <!-- <v-row>
          <v-col cols=12 class="mx-4">
              <router-link to="/">
                  <img class="mt-4" src="/static/svg/logo.png" alt width="124" height="20" style="float:center;" />
              </router-link>
              <v-divider class="my-4"></v-divider>
          </v-col>
      </v-row>
      <div v-if="!isLoggedIn">
          <v-row>
              <v-col cols=12 class="mx-4">
                  {{ $t(`label.welcomeToBrand`) }}
              </v-col>
          </v-row>
          <v-row justify="space-between" class="mx-1">
              <v-col cols="5">
                  <v-btn @click="this.openLoginDialog" color="black" class="mr-1 primary-button embedded-mobile-login-button font-weight-bold yellow--text">
                      {{ $t(`button.login`) }}
                  </v-btn>
              </v-col>
              <v-col cols="7">
                  <app-button :customClass="'buttonPrimary theme-button embedded-mobile-register-button'" :action="this.openRegisterDialog" :title="$t(`label.signUp`)"></app-button>
              </v-col>
          </v-row>
      </div>
      <div v-if="isLoggedIn">
          <v-row justify="space-between" class="mx-1">
              <v-col cols=12>
                  <label class="color-primary">{{ $t(`label.hi`) }}, {{ memberInfo.memberCode }}</label>
              </v-col>
          </v-row>
          <v-row justify="space-between" class="mx-1">
              <v-col cols=12>
                  <v-card @click="goToDepositPage()" elevation="0" class="pa-1 color-base header-wallet text-center">
                      <label class="color-primary text-capitalize" v-if="notLoading">
                          {{ memberWallet.totalBalance|currency(currentCurrency) }}
                      </label>
                  </v-card>
              </v-col>
          </v-row>
          <v-row class="mx-1">
              <v-col cols=2>
                  <vip-icon style="fill:#FDDD04"></vip-icon>
              </v-col>
              <v-col cols=10 align-self="center">
                  {{ vipProgress.current_level_name }}
              </v-col>
          </v-row>
          <v-row no-gutters class="mx-4">
              <v-col v-if="vipProgress.member_progress_type == 3" cols=12 class="mb-2">
                  <v-progress-linear :value="vipRolloverProgress" class="vip-progress-bar" height="14" rounded>
                      <template v-slot:default="{ value }">
                          <label>Rollover:
                              ({{ vipProgress.current_rollover_progress }}/{{
                  vipProgress.required_account_rollover
                }})</label>
                      </template>
                  </v-progress-linear>
              </v-col>
              <v-col v-if="vipProgress.member_progress_type == 2" cols=12 class="mb-2">
                  <v-progress-linear :value="vipDepositProgress" class="vip-progress-bar" height="14" rounded>
                      <template v-slot:default="{ value }">
                          <label>Deposit:
                              ({{ vipProgress.current_deposit_progress }}/{{
                  vipProgress.required_deposit_amount
                }})</label>
                      </template>
                  </v-progress-linear>
              </v-col>
          </v-row>
          <v-row justify="space-between" class="mx-1">
              <v-col cols=3>
                  <v-btn icon :to="{ name: routeName.PERSONAL }">
                      <account-profile class="mobile-icon"></account-profile>
                  </v-btn>
                  <p class="text-center">{{ $t(`label.profile`) }}</p>
              </v-col>
              <v-col cols=3>
                  <v-btn icon :to="{ name: routeName.TRANSACTION_HISTORY }">
                      <account-history class="mobile-icon"></account-history>
                  </v-btn>
                  <p class="text-center">{{ $t(`label.history`) }}</p>
              </v-col>
              <v-col cols=3>
                  <v-btn icon to="/bank/deposit">
                      <account-wallet></account-wallet>
                  </v-btn>
                  <p class="text-center">{{ $t(`label.deposit`) }}</p>
              </v-col>
              <v-col cols=3>
                  <v-btn icon to="/bank/deposit">
                      <account-withdrawal class="mobile-icon"></account-withdrawal>
                  </v-btn>
                  <p class="text-center">{{ $t(`label.withdrawal`) }}</p>
              </v-col>
          </v-row>
      </div> -->

      <v-list nav class="mobile_list_menu">
        <v-list-item-group active-class="">
          <v-list-item class="side-nav-logo">
            <v-img max-width="120px" src="/static/image/home/logo3.png" alt :contain="true"></v-img>
          </v-list-item>
          <v-list-item to="/home">
            <v-row no-gutters class="list-item-container">
              <v-list-item-icon class="side-menu-icon-wrapper">
                <img :src="`/static/svg/mobileMenu/home.png`" alt="JAYA9 cricket" class="side-menu-icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(`label.home`) }}</v-list-item-title>
              </v-list-item-content>
            </v-row>
          </v-list-item>

          <v-list-item
            v-for="gameType in storageGameProviderType"
            :key="gameType.type"
            :to="{
              name:
                gameType.type == 'sb'
                  ? routeName.SPORTSBOOK
                  : gameType.type == 'rng'
                  ? routeName.SLOT
                  : gameType.type == 'ld'
                  ? routeName.CASINO
                  : gameType.type == 'fishing'
                  ? routeName.FISHING
                  : gameType.type == 'special'
                  ? routeName.SPECIAL
                  : gameType.type == 'table'
                  ? routeName.TABLE_GAMES
                  : gameType.type == 'cricket'
                  ? routeName.CRICKET
                  : gameType.type == 'lottery'
                  ? routeName.LOTTERY
                  : gameType.type == 'crash'
                  ? routeName.CRASH
                  : routeName.HOME
            }"
          >
            <v-row no-gutters class="list-item-container">
              <v-list-item-icon class="side-menu-icon-wrapper">
                <img :src="`/static/svg/mobileMenu/${gameType.type}.png`" alt class="side-menu-icon" alt="JAYA9 exchange" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(`label.${gameType.type}`) }}</v-list-item-title>
              </v-list-item-content>
            </v-row>
          </v-list-item>

          <v-list-item to="/promotion" class="list-item-standalone">
            <v-row no-gutters class="list-item-container">
              <v-list-item-icon class="side-menu-icon-wrapper">
                <img :src="`/static/svg/mobileMenu/promo.png`" alt="JAYA9 promotion" class="side-menu-icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(`label.promotion`) }}</v-list-item-title>
              </v-list-item-content>
            </v-row>
          </v-list-item>
          <v-list-item :to="{ name: routeName.REFERRAL_SUMMARY }">
            <v-row no-gutters class="list-item-container">
              <v-list-item-icon class="side-menu-icon-wrapper">
                <img :src="`/static/svg/mobileMenu/referral.png`" alt="JAYA9 referral" class="side-menu-icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(`label.referral`) }}</v-list-item-title>
              </v-list-item-content>
            </v-row>
          </v-list-item>

          <v-list-item :to="{ name: routeName.VIP }">
            <v-row no-gutters class="list-item-container">
              <v-list-item-icon>
                <img :src="`/static/svg/mobileMenu/krikbet.png`" class="side-menu-icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <v-row no-gutters justify="start" align="center">
                    <v-col cols="auto" class="mt-1 breakWord-onspace">{{ $t(`label.bettingPassNavName`) }}</v-col>
                    <div class="text-center" style="margin-left: 2px; background-color: #04b22b; border-radius: 8px; color: white; font-size: 10px;width:30px">
                      <span class="font-weight-bold">{{ $t(`label.new`) }}</span>
                    </div>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-row>
          </v-list-item>

          <!--          <v-list-item @click="downloadAPK" v-if="this.isDAndroid">-->
          <!--            <v-row no-gutters class="list-item-container">-->
          <!--              <v-list-item-icon class="side-menu-icon-wrapper">-->
          <!--                <img :src="`/static/svg/mobileMenu/apkDownload.png`" alt="krikya apk" class="side-menu-icon"/>-->
          <!--              </v-list-item-icon>-->
          <!--              <v-list-item-content>-->
          <!--                <v-list-item-title class="breakWord-onspace">{{ $t(`label.download`) }}</v-list-item-title>-->
          <!--              </v-list-item-content>-->
          <!--            </v-row>-->
          <!--          </v-list-item>-->

          <!-- <v-list-item :to="{ name: routeName.VIP }">
              <v-list-item-icon class="side-menu-icon-wrapper">
                  <img :src="`/static/svg/mobileMenu/vip.png`" alt class="side-menu-icon" />
              </v-list-item-icon>
              <v-list-item-content>
                  <v-list-item-title> -->
          <!-- <v-badge right overlap color="light" offset-x="5" offset-y="7">
          <template v-slot:badge>
              <img src="/static/svg/game-icon-new.png" height="20" width="20"/>
              <span class="font-weight-bold" style="background-color:green;border-radius:15px;color:white; padding:2px; font-size:10px">  {{ $t(`label.new`) }} </span>
          </template>
          {{ $t(`label.vip`) }}
          </v-badge> -->
          <!-- <v-row no-gutters justify="start" align="center">
                          <v-col cols="auto" class="mt-1">{{ $t(`label.vip`) }}</v-col>
                          <v-col cols="2" class="text-center" style="margin-left: 2px; background-color: #04b22b; border-radius: 8px; color: white; font-size: 10px">
                              <span class="font-weight-bold">{{ $t(`label.new`) }}</span>
                          </v-col>
                      </v-row>
                  </v-list-item-title>
              </v-list-item-content>
          </v-list-item>  -->

          <v-list-item @click="logout" v-if="isLoggedIn">
            <v-row no-gutters class="list-item-container">
              <v-list-item-icon>
                <img :src="`/static/svg/mobileMenu/logout.svg`" alt="JAYA9 crazytime exchange" class="side-menu-icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(`label.logout`) }}</v-list-item-title>
              </v-list-item-content>
            </v-row>
          </v-list-item>

          <v-list-item @click="openLanguageDialog()">
            <v-row no-gutters class="list-item-container">
              <v-list-item-icon :class="['side-menu-icon-wrapper ml-1', isLoggedIn ? 'mt-0' : 'mt-3']">
                <v-avatar size="30" class="language-button">
                  <img :src="`/static/image/country/${currentCurrency}.svg`" alt="JAYA9 cricket exchange" />
                </v-avatar>
              </v-list-item-icon>
              <v-list-item-content :class="['pl-1', isLoggedIn ? 'pt-0' : '']">
                <v-list-item-title>{{ $t(`label.language`) }}</v-list-item-title>
              </v-list-item-content>
            </v-row>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <app-page-navigator></app-page-navigator>

    <div :class="`body ${!isIos && $vuetify.breakpoint.smAndDown && showDownloadAPK ? 'download-bar-height-body' : ''}`">
      <router-view></router-view>
      <app-footer></app-footer>
    </div>

    <div class="bottom-navigator d-md-none">
      <v-row no-gutters v-if="!isLoggedIn" class="bottom-navigator-before-login">
        <v-col cols="4" @click="openLanguageDialog()" class="bottom-navigator-before-login-first d-flex flex-row justify-center align-center no-gutters">
          <div class="d-flex flex-column justify-center align-center">
            <v-btn icon small>
              <v-avatar size="25" class="language-button">
                <img :src="`/static/image/country/${currentCurrency}.svg`" alt="JAYA9 cricket exchange" />
              </v-avatar>
            </v-btn>
          </div>
          <div no-gutters class="d-flex flex-column justify-center align-center">
            <span v-for="l in language" :key="l.id" style="font-size: 12px">{{ l.title }}</span>
          </div>
        </v-col>
        <v-col cols="4" @click="openLoginDialog()" class="bottom-navigator-before-login-third d-flex justify-center align-center no-gutters">
          <b>{{ $t(`label.login`) }}</b>
        </v-col>
        <v-col cols="4" @click="openRegisterDialog()" class="bottom-navigator-before-login-second d-flex justify-center align-center no-gutters">
          <b>{{ $t(`label.register`) }}</b>
        </v-col>
      </v-row>

      <v-row no-gutters v-if="isLoggedIn">
        <v-col cols="auto" class="grow">
          <v-btn depressed height="auto" tile :ripple="false" exact exact-active-class="link-active" to="/home">
            <div class="text-center">
              <!-- <account-home class="icon_btm_nav"></account-home> -->
              <img class="icon_btm_nav" :src="`/static/svg/mobileBottomNavigation/toolbar-icon-home.svg`" alt="JAYA9 crazytime exchange" />
              <p>{{ $t(`label.home`) }}</p>
            </div>
          </v-btn>
        </v-col>
        <v-col cols="auto" class="grow">
          <v-btn depressed height="auto" tile :ripple="false" exact exact-active-class="link-active" to="/promotion">
            <div class="text-center">
              <img class="icon_btm_nav" :src="`/static/svg/mobileBottomNavigation/toolbar-icon-promotion.svg`" alt="JAYA9 crazytime exchange" />
              <p>{{ $t(`label.promotion`) }}</p>
            </div>
          </v-btn>
        </v-col>
        <v-col cols="auto" class="grow">
          <v-btn depressed height="auto" tile :ripple="false" exact exact-active-class="link-active" to="/funds">
            <div class="text-center">
              <img class="icon_btm_nav" :src="`/static/svg/mobileBottomNavigation/toolbar-icon-deposit.svg`" alt="JAYA9 crazytime exchange" />
              <p>{{ $t(`label.deposit`) }}</p>
            </div>
          </v-btn>
        </v-col>
        <v-col cols="auto" class="grow">
          <v-btn depressed height="auto" tile :ripple="false" to="#" @click="openProfileSheet">
            <div class="text-center">
              <img class="icon_btm_nav" :src="`/static/svg/mobileBottomNavigation/toolbar-icon-mine.svg`" alt="JAYA9 crazytime exchange" />
              <p>{{ $t(`label.profile`) }}</p>
            </div>
          </v-btn>
        </v-col>

        <!-- <v-col cols="auto" class="grow">
            <v-btn depressed height="auto" tile :ripple="false" exact exact-active-class="link-active" :to="{ name: routeName.REWARDS }">
                <div class="text-center">
                    <img class="icon_btm_nav" :src="`/static/svg/mobileBottomNavigation/rewards.svg`" alt />
                    <p>{{ $t(`label.rewards`) }}</p>
                </div>
            </v-btn>
        </v-col>
        <v-col cols="auto" class="grow">
            <v-btn depressed height="auto" tile :ripple="false" exact exact-active-class="link-active" :to="{ name: routeName.REFERRAL_SUMMARY }">
                <div class="text-center">
                    <img class="icon_btm_nav" :src="`/static/svg/mobileBottomNavigation/referral.svg`" alt />
                    <p>{{ $t(`label.referral`) }}</p>
                </div>
            </v-btn>
        </v-col>-->
      </v-row>
    </div>

    <profile-sheet :showProfileSheet="showProfileSheet" :closeProfileSheet="closeProfileSheet" :logout="logout"></profile-sheet>
    <app-floating-contact-us></app-floating-contact-us>
    <!-- WAIT REGISTER DIALOG -->
    <app-dialog :dialogShow="waitRegisterDialogShow" :max-width="600" :title="$t(`label.wait`)" :closeAction="closeWaitRegisterDialog">
      <app-wait-register :openRegisterDialog="openRegisterDialog" :openLoginDialog="openLoginDialog"></app-wait-register>
    </app-dialog>

    <!-- REGISTER DIALOG -->
    <app-dialog
      :dialogShow="registerDialogShow"
      :max-width="400"
      :min-height="724"
      :title="$t(`label.signUp`)"
      :isAuth="false"
      :header_title="'register'"
      :openLoginDialog="openLoginDialog"
      :closeAction="this.closeRegisterDialog"
    >
      <app-register :openLoginDialog="this.openLoginDialog" :registerErrorMsg="this.registerErrorMsg"></app-register>
    </app-dialog>

    <!-- FORGOT PASSWORD DIALOG -->
    <app-dialog :dialogShow="forgotPasswordDialogShow" :max-width="400" :title="$t(`label.forgotPassword`).slice(0, -1)" :closeAction="this.closeForgotPasswordDialog" :customClass="'pa-0'">
      <app-forgot-password :openRegisterDialog="openRegisterDialog" :openLoginDialog="this.openLoginDialog"></app-forgot-password>
    </app-dialog>

    <!-- RESET PASSWORD DIALOG -->
    <app-dialog :dialogShow="resetPasswordDialogShow" :max-width="400" :title="$t(`label.resetPassword`)" :closeAction="this.closeResetPasswordDialog">
      <app-reset-password :resetPasswordMemberCode="resetPasswordObj.memberCode" :resetPasswordContact="resetPasswordObj.contact"></app-reset-password>
    </app-dialog>

    <!-- LOGIN DIALOG -->
    <app-dialog
      :dialogShow="loginDialogShow"
      ref="loginDialog"
      :max-width="400"
      :title="$t(`label.welcomeToBrand`)"
      :isAuth="false"
      :header_title="'login'"
      :openRegisterDialog="openRegisterDialog"
      :closeAction="this.closeLoginDialog"
      :customClass="'pa-0'"
    >
      <app-login
        :openRegisterDialog="this.openRegisterDialog"
        :loginErrorMsg="this.loginErrorMsg"
        :openForgotPasswordDialog="this.openForgotPasswordDialog"
        :closeAction="this.closeLoginDialog"
      ></app-login>
    </app-dialog>

    <!-- REGISTER SUCCESS DIALOG -->
    <app-dialog
      :dialogShow="registerSuccessDialogShow"
      :isRegSuccess="true"
      ref="registerSuccessDialog"
      :max-width="400"
      :title="$t(`label.registerSuccess`)"
      :closeAction="this.closeRegisterSuccessDialog"
    >
      <app-register-success :closeRegisterSuccessDialog="closeRegisterSuccessDialog"></app-register-success>
    </app-dialog>

    <!--PT Quick Transfer Dialog-->
    <app-dialog :dialogShow="quickTransferDialogShow" :max-width="450" :title="this.transferMessage" :closeAction="this.closeQuickTransferDialog">
      <app-transfer-component :isNonBanking="true" :provider="this.transferToProvider" :ptGameInfo="this.ptGameInfo"></app-transfer-component>
    </app-dialog>

    <!-- PAGE DIALOG -->
    <app-dialog :dialogShow="pageDialogShow" :max-width="400" :title="pageDialog.title" :closeAction="this.pageDialog.dialogXButton">
      <app-page-dialog :dialogMessageTitle="pageDialog.messageTitle" :dialogMessage="pageDialog.message" :dialogButton="pageDialog.button"></app-page-dialog>
    </app-dialog>

    <!-- INSTANT POPUP DIALOG -->
    <instant-popup-dialog :dialog-show="instantDialogShow" :max-width="438" :closeAction="this.closeInstantPopup" :array-info="popupAnnouncement"></instant-popup-dialog>

    <!-- LANGUAGE DIALOG -->
    <app-dialog
      :dialogShow="languageDialogShow"
      ref="languageDialog"
      :max-width="300"
      :title="$t(`label.currencyLanguage`)"
      :isAuth="false"
      :header_title="'login'"
      :closeAction="this.closeLanguageDialog"
      :customClass="'pa-0'"
    >
      <app-language :closeAction="this.closeLanguageDialog"></app-language>
    </app-dialog>

    <app-loading-progress ref="loading"></app-loading-progress>

    <!--        <v-btn class="full-width font-weight-bold px-4 py-2 theme-button liveChatButton" height="auto"-->
    <!--               @click="openLiveChat">-->
    <!--            {{ $t(`label.liveChat`) }}-->
    <!--            <img :src="`${mediaUrl}/others_icons/live-chat.svg`" width="30" height="42"-->
    <!--                 class="btn_img_lc"/>-->
    <!--        </v-btn>-->
  </div>
</template>

<script>
import _ from 'lodash'
import { format } from 'date-fns'
import moment from 'moment'
import { GRAB_COIN_SETTING } from '@/store/bonus.module'
import AccountLiveChat from '@/assets/svg/liveChat.svg'
import AppTransferComponent from '@/views/bank/transfer'
import AccountProfile from '@/assets/svg/account-profile.svg'
import AccountWallet from '@/assets/svg/account-deposit.svg'
import AccountWithdrawal from '@/assets/svg/account-withdrawal.svg'
import AccountHistory from '@/assets/svg/account-history.svg'
import NavTransfer from '@/assets/svg/transfer.svg'
import NavDeposit from '@/assets/svg/deposit.svg'
import NavWithdrawal from '@/assets/svg/withdrawal.svg'
import NavLogout from '@/assets/svg/logout.svg'
import vipIcon from '@/assets/svg/vip.svg'
import InstantPopupDialog from '@/components/InstantPopupDialog.vue'
import AppFloatingContactUs from '@/components/home/FloatingContactUs'

import { AnnouncementType, DevicePlatform } from '@/constants/enums'
import { ROUTE_NAME } from '@/constants/route.constants'
import AppRegister from '@/components/member/register.vue'
import AppRegisterSuccess from '@/components/member/registerSuccess.vue'
import AppLoginEmbedded from '@/components/member/loginEmbedded.vue'
import AppWaitRegister from '@/components/member/waitRegister.vue'
import AppForgotPassword from '@/components/member/forgotPassword.vue'
import AppResetPassword from '@/components/member/resetPassword.vue'
import AppPageDialog from '@/components/layout/PageDialog.vue'
import AppPageNavigator from '@/components/layout/PageNavigator.vue'
import AppFooter from '@/components/layout/Footer.vue'
import AppLoadingProgress from '@/components/layout/LoadingProgress.vue'
import AppLogin from '@/components/member/login.vue'
import AppLanguage from '@/components/layout/Language.vue'
import { SESSION, SHARED, CACHE_KEY } from '@/constants/constants'
import { locale, errorCodeHelper, uiHelper } from '@/util'
import * as eventBus from 'vue'
import {
  MEMBER_RESET_LOGIN_STORE,
  MEMBER_LOGOUT,
  MEMBER_REFRESH_TOKEN,
  MEMBER_RESET_LOGOUT_STORE,
  MEMBER_CHECK_TOKEN_VALIDITY,
  MEMBER_WALLET,
  MEMBER_RESET_FORGOT_PASSWORD_STORE,
  MEMBER_RESET_RESET_PASSWORD_STORE,
  MEMBER_CONTACT_VERIFICATION,
  MEMBER_RESET_CONTACT_VERIFICATION_STORE,
  MEMBER_RESET_REGISTER_STORE,
  MEMBER_GET_VIP_PROGRESS,
  MEMBER_RESET_SMS_VERIFICATION,
  MEMBER_RESTRICT_WITHDRAWAL,
  MEMBER_RESET_UPDATE_FORGOT_PASSWORD
} from '@/store/member.module'
import { POPUP_LIST } from '@/store/announcement.module'
import { WHEEL_MODULE } from '@/store/wheel.module'
import { INBOX_GET_MSG } from '@/store/inbox.module'
import ButtonPrimary from '../ButtonPrimary.vue'

export default {
  name: 'layoutMaster',
  components: {
    AppFloatingContactUs,
    AccountLiveChat,
    AppTransferComponent,
    AccountProfile,
    AccountWallet,
    AccountWithdrawal,
    AccountHistory,
    AppLogin,
    AppLanguage,
    AppRegister,
    AppRegisterSuccess,
    AppLoginEmbedded,
    AppForgotPassword,
    AppResetPassword,
    AppPageDialog,
    AppPageNavigator,
    AppFooter,
    AppLoadingProgress,
    AppWaitRegister,
    NavDeposit,
    NavWithdrawal,
    NavLogout,
    NavTransfer,
    vipIcon,
    InstantPopupDialog,
    ButtonPrimary
  },
  data: () => ({
    showBalance: true,
    showDownloadAPK: true,
    isIos: uiHelper.IsIos(),
    isDAndroid: uiHelper.isAndroid(),
    showProfileSheet: false,
    time: '',
    instantDialogShow: false,
    instantPopupAnnouncement: [],
    drawer: false,
    notLoading: true,
    currentLanguage: uiHelper.getLanguage(),
    currentCurrency: uiHelper.getCurrency(),
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    transferMessage: locale.getMessage(`label.ptQuickTransfer`, ['PT']),
    isTransferWalletLoginDialog: false,
    ptGameInfo: null,
    transferToProvider: 'PT',
    devicePlatform: DevicePlatform,
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    uihelper: uiHelper,
    quickTransferDialogShow: false,
    registerSuccessDialogShow: false,
    waitRegisterDialogShow: false,
    registerDialogShow: false,
    loginDialogShow: false,
    forgotPasswordDialogShow: false,
    resetPasswordDialogShow: false,
    languageDialogShow: false,
    firstTimeRegister: true,
    routeName: ROUTE_NAME,
    resetPasswordObj: {
      contact: '',
      memberCode: ''
    },
    pageDialogShow: false,
    pageDialog: {
      title: '',
      closePageDialog: () => {},
      messageTitle: '',
      message: [],
      button: []
    },
    memberLink: SHARED.MEMBER_HEADER_NAVIGATOR_LINK,
    loginErrorMsg: '',
    registerErrorMsg: '',
    language: [
      {
        title: 'English',
        cd: 'en'
      },
      {
        title: 'Bengali',
        cd: 'bd'
      }
    ],
    languageList: [
      {
        currency: 'BDT',
        currencyCd: '৳',
        img: 'BDT',
        language: [
          {
            title: 'English',
            cd: 'en'
          },
          {
            title: 'Bengali',
            cd: 'bd'
          }
        ]
      }
    ]
  }),
  computed: {
    popupAnnouncement() {
      let p = []
      let k = this.$store.state.announcement.popupAnnouncement
      k.forEach(announcement => {
        p.push({
          id: announcement.id,
          date: announcement.announcementDate,
          title:
            announcement.titleLocale.find(x => x.language == this.currentLanguage) != null
              ? announcement.titleLocale.find(x => x.language == this.currentLanguage).title
              : announcement.titleLocale.find(x => x.language == 'en') != null
              ? announcement.titleLocale.find(x => x.language == 'en').title
              : announcement.titleLocale[0].title,
          message:
            announcement.messageLocale.find(x => x.language == this.currentLanguage) != null
              ? announcement.messageLocale.find(x => x.language == this.currentLanguage).message
              : announcement.messageLocale.find(x => x.language == 'en') != null
              ? announcement.messageLocale.find(x => x.language == 'en').message
              : announcement.messageLocale[0].message
        })
      })
      if (p.length > 0) {
        this.openInstantPopup()
      }
      return p
    },
    hasUnreadInboxMsg() {
      return this.$store.state.inbox.inboxMsg.filter(m => !m.has_read).length > 0
    },
    grabCoin() {
      let d = this.$store.state.bonus.grabCoinSettings
      return d
    },
    wof() {
      return this.$store.state.wheel.wheel
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    loginResponseComplete() {
      return this.$store.state.member.loginResponse.complete
    },
    verifyContactResponseComplete() {
      return this.$store.state.member.verifyContactResponse.complete
    },
    resetPasswordResponseComplete() {
      return this.$store.state.member.resetPasswordResponse.complete
    },
    registerResponseComplete() {
      return this.$store.state.member.registerResponse.complete
    },
    forgotPasswordResponseComplete() {
      return this.$store.state.member.forgotPasswordResponse.complete
    },
    logoutResponseComplete() {
      return this.$store.state.member.logoutResponse.complete
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    vipLevel() {
      if (uiHelper.getCookie(SESSION.VIP) == null) {
        let m = this.$store.state.member.info
        let vip = m.group
        uiHelper.setCookie(SESSION.VIP, vip, 1)
        return vip
      }
      return uiHelper.getCookie(SESSION.VIP)
    },
    memberWallet() {
      return this.$store.state.member.walletBalance
    },
    vipProgress() {
      return this.$store.state.member.vipProgress
    },
    vipDepositProgress() {
      return (this.vipProgress.current_deposit_progress / this.vipProgress.required_deposit_amount) * 100
    },
    vipRolloverProgress() {
      return (this.vipProgress.current_rollover_progress / this.vipProgress.required_account_rollover) * 100
    },
    ProviderTypes() {
      return this.$store.state.game.ProviderTypes
    },
    changeForgotPasswordResponse() {
      return this.$store.state.member.changeForgotPassword.complete
    },
    languageSelected() {
      for (const lang of this.languageList) {
        if (lang.currency == this.currentCurrency) {
          // const language = lang.find(l => {
          //     // console.log('language', language);
          //     console.log('l', l);
          // });
          return `${lang.currencyCd} ${lang.currency}`
        }
      }
      return null
    }
  },
  watch: {
    changeForgotPasswordResponse() {
      //new forgotpassword flow
      let response = this.$store.state.member.changeForgotPassword
      if (response.complete) {
        try {
          this.$ga.event('Account', `Reset Password`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        this.updateForgotPasswordCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_UPDATE_FORGOT_PASSWORD}`)
      }
    },
    isLoggedIn() {
      if (this.isLoggedIn) {
        // this.getMemberWallet()
      }
    },
    ProviderTypes() {
      this.storageGameProviderType = this.$store.state.game.ProviderTypes
    },
    memberInfo() {
      let m = this.$store.state.member.info
      let vip = m.group
      uiHelper.setCookie(SESSION.VIP, vip, 1)
      this.currentCurrency = uiHelper.getCurrency()

      // if(this.currentLanguage != m.preferredLanguage && !uiHelper.getCookie("changeLanguage")){
      //   uiHelper.removeCookie('language')
      //   uiHelper.setCookie('language', m.preferredLanguage)
      //   this.currentLanguage = m.preferredLanguage
      //   window.location.reload()
      // }
    },
    memberWallet() {
      this.notLoading = true
    },
    registerResponseComplete() {
      let response = this.$store.state.member.registerResponse
      if (response.complete) {
        try {
          this.$ga.event('Account', `Register`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        this.registerResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_REGISTER_STORE}`)
      }
    },
    loginResponseComplete() {
      let response = this.$store.state.member.loginResponse
      /* Check Path Should Reload */
      if (this.$route.fullPath == '/sportsbook/bti') {
        window.location.reload()
      }
      if (response.complete) {
        try {
          this.$ga.event('Account', `Login`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        if (response.success) {
          this.setGoogleAnalyticUserId()
        }
        this.loginResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_LOGIN_STORE}`)
      }
    },
    logoutResponseComplete() {
      let response = this.$store.state.member.logoutResponse
      if (response.complete) {
        try {
          this.$ga.event('Account', `Logout`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        this.logoutResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_LOGOUT_STORE}`)
      }
    },
    verifyContactResponseComplete() {
      let response = this.$store.state.member.verifyContactResponse
      if (response.complete) {
        try {
          this.$ga.event('Account', `Verify Contact`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        this.verifyContactResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_CONTACT_VERIFICATION_STORE}`)
      }
    },
    resetPasswordResponseComplete() {
      let response = this.$store.state.member.resetPasswordResponse
      if (response.complete) {
        try {
          this.$ga.event('Account', `Reset Password`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        this.resetPasswordResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_RESET_PASSWORD_STORE}`)
      }
    },
    forgotPasswordResponseComplete() {
      let response = this.$store.state.member.forgotPasswordResponse
      if (response.complete) {
        try {
          this.$ga.event('Account', `Forgot Password`, `${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`)
        } catch (err) {
          console.log(err)
        }
        this.forgotPasswordResponseCompleteDialog(response)
        this.$store.dispatch(`${MEMBER_RESET_FORGOT_PASSWORD_STORE}`)
      }
    }
  },
  created() {
    this.isAndroid()
    this.timeInterval()
    this.getPopupAnnouncement()
    this.pageDialog = this.initializePageDialogProperty()
    if (localStorage.getItem(SESSION.TOKEN)) {
      this.getMemberVipProgress()
      this.validateMemberToken()
    }
    this.setGoogleAnalyticUserId()
    this.getInboxMsg()
    this.$eventHub.$on('open-page-dialog', dialog => {
      this.openPageDialog(dialog)
    })
    this.$eventHub.$on('close-page-dialog', unt => {
      this.closePageDialog()
    })
    this.$eventHub.$on('open-quick-transfer-dialog', gameInfo => {
      this.openQuickTransfer(gameInfo)
    })
    this.$eventHub.$on('close-quick-transfer-dialog', dialog => {
      this.closeQuickTransferDialog()
    })
    this.$eventHub.$on('open-login-dialog', obj => {
      if (obj != null) {
        this.isTransferWalletLoginDialog = obj.isTransfer
      }
      this.openLoginDialog()
    })
    this.$eventHub.$on('close-login-dialog', dialog => {
      this.closeLoginDialog()
    })
  },
  methods: {
    getMemberWallet() {
      if (!this.lastDispatchTime || Date.now() - this.lastDispatchTime > 15 * 1000) {
        this.notLoading = false
        this.lastDispatchTime = Date.now() // Update last dispatch time
        this.$store.dispatch(MEMBER_WALLET)
      }
      this.maskBalance()
    },
    maskBalance() {
      this.showBalance = !this.showBalance
    },
    TrackJayaS2S() {
      let cid = sessionStorage.getItem(SESSION.CID)
      let url = 'https://track.jaya21.com/postback?cid={0}&currency=OPTIONAL'
      if (cid) {
        url = url.replace('{0}', cid)
        fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          }
        })
          .then(res => res.json())
          .then(res => console.log(res))
          .catch(err => console.log('error on trackjayas2s: ' + err))
      }
    },
    InjectRegSucceedPixel() {
      var a = 'https://jaya21.com/conversion.js?cid=OPTIONAL&payout=OPTIONAL&txid=OPTIONAL'
      var c = document.createElement('script')
      var d = document.scripts[0]
      var b = document.cookie.match(/(^| )vl-cid=([^;]+)/)
      if (b) {
        if (a.indexOf('cid=') !== -1) {
          a = a.replace(/cid=.*?(&|$)/, 'cid=' + b.pop() + '&')
        } else {
          var e = a.indexOf('?') !== -1 ? '&' : '?'
          a += e + 'cid=' + document.cookie.pop()
        }
      }

      c.src = a
      d.parentNode.insertBefore(c, d)
    },
    initShowDownloadAPK() {
      this.showDownloadAPK = true
    },
    hideDownloadBar() {
      this.showDownloadAPK = false
    },
    refreshCurrentState() {
      this.$router.push({
        name: ROUTE_NAME.HOME
      })
      this.$forceUpdate()
    },
    downloadAPK() {
      uiHelper.downloadAPK()
    },
    isAndroid() {
      try {
        let isAndroid = AndroidFunction.isMobile() ?? false
        uiHelper.setLocalStorage(CACHE_KEY.IS_ANDROID, isAndroid, 60)
      } catch (e) {
        uiHelper.setLocalStorage(CACHE_KEY.IS_ANDROID, false, 60)
      }
    },
    timeInterval() {
      let gmt = moment
        .parseZone()
        .toString()
        .slice(-8)
      gmt = gmt.substring(0, 4) + gmt.substring(5, 6) + ':' + gmt.substring(6, 8)
      setInterval(() => {
        this.time = `(${gmt}) ${moment()
          .utcOffset(0, true)
          .format('HH:mm:ss')}`
      }, 1000)
    },
    async getPopupAnnouncement() {
      let obj = {
        site: uiHelper.getPopup(),
        type: AnnouncementType.ALL,
        page: '',
        itemsPerPage: ''
      }
      await this.$store.dispatch(`${POPUP_LIST}`, {
        obj
      })
    },
    openInstantPopup() {
      let dayClose = uiHelper.getCookie('popup')
      if (dayClose == null) {
        this.instantDialogShow = true
      }
    },
    closeInstantPopup() {
      let dayClose = uiHelper.getCookie('popup')
      if (dayClose == null) {
        dayClose = 'yes'
      }
      uiHelper.setCookie('popup', 'yes', 0.5)
      this.instantDialogShow = false
    },
    getInboxMsg() {
      if (this.isLoggedIn) {
        this.$store.dispatch(`${INBOX_GET_MSG}`)
      }
    },
    navigateToCoinPage() {
      this.$router.push({
        name: ROUTE_NAME.GRAB_COIN,
        params: {
          type: 'coin'
        }
      })
    },
    getGrabCoinSetting() {
      let filter = {
        currency: uiHelper.getCurrency()
      }
      this.$store.dispatch(`${GRAB_COIN_SETTING}`, {
        filter
      })
    },
    checkShouldHideCoin() {
      if (typeof uiHelper.getCookie(SESSION.HIDE_COIN) != 'undefined' && uiHelper.getCookie(SESSION.HIDE_COIN) == 1) {
        this.showCoin = false
      } else {
        this.showCoin = true
      }
    },
    navigateWOFPage() {
      if (uiHelper.getPlatform() == DevicePlatform.DESKTOP) {
        this.$router.push({
          name: ROUTE_NAME.WOF
        })
      } else {
        this.$router.push({
          name: ROUTE_NAME.MWOF
        })
      }
    },
    getWOF() {
      let currency = uiHelper.getCurrency()
      let filter = {
        currency: currency
      }
      this.$store.dispatch(`${WHEEL_MODULE}`, {
        filter
      })
    },
    checkShouldHideAngpao() {
      if (typeof uiHelper.getCookie(SESSION.HIDE_ANG_PAO) != 'undefined' && uiHelper.getCookie(SESSION.HIDE_ANG_PAO) == 1) {
        this.showAngpao = false
      } else {
        this.showAngpao = true
      }
    },
    dontShowMeAgain() {
      this.showAngpao = false
      uiHelper.setCookie(SESSION.HIDE_ANG_PAO, 1, 0.5)
    },
    navigateToAngpao() {
      this.$router.push({
        name: ROUTE_NAME.REWARDS_BY_TYPE,
        params: {
          type: 'angpao'
        }
      })
    },
    setGoogleAnalyticUserId() {
      if (this.memberInfo.memberCode != '') this.$ga.set('userId', this.memberInfo.memberCode)
    },
    openLiveChat() {
      try {
        this.$ga.event('Live Chat', `Open`, `Open Live Chat`)
      } catch (err) {
        console.log(err)
      }
      window.open('/livechat', 'Live Chat', 'width=350,height=600')
    },
    goToTransferPage() {
      this.$router.push({
        name: ROUTE_NAME.TRANSFER
      })
    },
    goToWithdrawalPage() {
      this.$router.push({
        name: ROUTE_NAME.WITHDRAWAL
      })
    },
    goToDepositPage() {
      // if (uiHelper.getPlatform() == DevicePlatform.MOBILE) {
      //   let routeData = this.$router.resolve({
      //     name: ROUTE_NAME.DEPOSIT
      //   })
      //   window.open(routeData.href)
      // } else {
      this.$router.push({
        name: ROUTE_NAME.FUNDS
      })
      //}
    },
    goToProfilePage() {
      // this.$router.push({
      //     name: ROUTE_NAME.PERSONAL,
      // })
      this.showProfileSheet = true
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    validateMemberToken() {
      if (new Date(parseInt(localStorage.getItem(SESSION.TOKEN_EXPIRY))) - new Date() < 60000) {
        if (new Date() - new Date(parseInt(localStorage.getItem(SESSION.LAST_ACTIVITY_TIME))) > 10800000) {
          const logoutObj = {}
          let sessionTimedOut = true
          this.$store.dispatch(MEMBER_LOGOUT, {
            logoutObj,
            sessionTimedOut
          })
        } else {
          this.$store.dispatch(MEMBER_REFRESH_TOKEN)
        }
      } else {
        let requiredLoading = false
        this.$store.dispatch(MEMBER_CHECK_TOKEN_VALIDITY, {
          requiredLoading
        })
      }
    },
    memberContactVerification(memberCode, otp, type, contact) {
      let verificationObj = {
        memberCode: memberCode,
        otp: otp,
        type: type,
        contact: contact
      }
      this.$store.dispatch(MEMBER_CONTACT_VERIFICATION, {
        verificationObj
      })
    },
    logout() {
      const logoutObj = {
        domain: uiHelper.getHostname(),
        platform: uiHelper.getPlatform()
      }
      let sessionTimedOut = false
      this.$store.dispatch(MEMBER_LOGOUT, {
        logoutObj,
        sessionTimedOut
      })
    },
    getMemberVipProgress() {
      this.$store.dispatch(MEMBER_GET_VIP_PROGRESS)
    },
    /* DIALOG */
    closeRegisterSuccessDialog() {
      this.registerSuccessDialogShow = false
    },
    closeQuickTransferDialog() {
      this.quickTransferDialogShow = false
      let routeData = this.$router.resolve({
        name: ROUTE_NAME.LAUNCH_GAME_LOBBY,
        params: {
          provider: this.ptGameInfo.provider,
          type: this.ptGameInfo.type
        },
        query: {
          id: this.ptGameInfo.id,
          code: this.ptGameInfo.code,
          demo: 0
        }
      })
      try {
        this.$ga.event('Game', `${0 ? 'Demo' : 'Real'} Play`, `${this.ptGameInfo.provider.toUpperCase()} - Slot - ${this.ptGameInfo.locale.find(x => x.language == 'en').name}`, this.ptGameInfo.id)
      } catch (err) {
        console.log(err)
      }
      uiHelper.openNewTab(routeData.href, 'Game Play Lobby', 1440, 810)
    },
    openRegisterDialog() {
      // this.registerDialogShow = true
      this.closeWaitRegisterDialog()
      this.closeLoginDialog()
      this.closeForgotPasswordDialog()
      this.$router.push({ name: ROUTE_NAME.REGISTER })
    },
    closeRegisterDialog(actionFromRegisterDialog = true) {
      this.registerDialogShow = false
      this.$store.dispatch(`${MEMBER_RESET_SMS_VERIFICATION}`)
      // if (this.firstTimeRegister && actionFromRegisterDialog) {
      //   this.openWaitRegisterDialog()
      //   this.firstTimeRegister = false
      // }
    },
    // openLoginDialog() {
    //   this.loginDialogShow = true
    //   this.closeWaitRegisterDialog()
    //   this.closeRegisterDialog(false)
    // },
    closeLoginDialog() {
      if (this.isTransferWalletLoginDialog) {
        this.$router.push({
          name: ROUTE_NAME.SLOT_BY_PROVIDER,
          params: {
            provider: 'all'
          }
        })
      }
      this.loginDialogShow = false
    },
    openForgotPasswordDialog() {
      this.forgotPasswordDialogShow = true
      this.closeLoginDialog()
    },
    closeForgotPasswordDialog() {
      this.forgotPasswordDialogShow = false
    },
    openResetPasswordDialog(memberCode, contact) {
      this.resetPasswordObj.memberCode = memberCode
      this.resetPasswordObj.contact = contact
      this.closeForgotPasswordDialog()
      this.resetPasswordDialogShow = true
    },
    closeResetPasswordDialog() {
      this.resetPasswordObj.memberCode = ''
      this.resetPasswordObj.contact = ''
      this.resetPasswordDialogShow = false
    },
    openLanguageDialog() {
      this.languageDialogShow = true
    },
    closeLanguageDialog() {
      this.languageDialogShow = false
    },
    openLoginDialog() {
      this.loginDialogShow = true
      this.closeForgotPasswordDialog()
      this.closeWaitRegisterDialog()
      this.closeRegisterDialog(false)
    },
    openQuickTransfer(gameInfo) {
      this.ptGameInfo = gameInfo
      this.quickTransferDialogShow = true
      this.transferToProvider = gameInfo.provider == 'pt' ? 'PT' : 'MAIN'
      this.transferMessage = locale.getMessage(`label.ptQuickTransfer`, [this.transferToProvider])
    },
    openProfileSheet() {
      this.showProfileSheet = true
    },
    closeProfileSheet() {
      this.showProfileSheet = false
    },
    openPageDialog(dialog) {
      this.pageDialog = dialog
      this.pageDialogShow = true
    },
    closeTimeOutDialog() {
      localStorage.removeItem(SESSION.TOKEN)
      this.closePageDialog()
      this.refreshCurrentState()
    },
    closePageDialog() {
      this.showProfileSheet = false
      this.pageDialogShow = false
      this.initializePageDialogProperty()
    },
    openWaitRegisterDialog() {
      this.waitRegisterDialogShow = true
    },
    closeWaitRegisterDialog() {
      this.waitRegisterDialogShow = false
    },
    /* RESPONSE COMPLETE DIALOG */
    loginResponseCompleteDialog(response) {
      if (response.code != 0 && !response.success) {
        this.loginErrorMsg = errorCodeHelper.getErrorCodeDesc(response.code)
      } else {
        this.closeLoginDialog()
        this.currentCurrency = uiHelper.getCurrency()
        this.closeRegisterDialog(false)
      }
    },
    logoutResponseCompleteDialog(response) {
      if (response.isSessionTimedOut) {
        this.$router.push({
          name: ROUTE_NAME.HOME
        })
        let dialog = this.initializePageDialogProperty()
        dialog.title = locale.getMessage(`label.${response.action}`)
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.closeTimeOutDialog
        })
        this.openPageDialog(dialog)
      }
    },
    updateForgotPasswordCompleteDialog(response) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.resetPassword`)
      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.closePageDialog
        })
      } else {
        let self = this
        dialog.message.push(locale.getMessage(`message.resetPasswordComplete`))
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: self.closeResetPasswordSuccessDialog
        })
      }
      this.closeResetPasswordDialog()
      this.openPageDialog(dialog)
    },
    forgotPasswordResponseCompleteDialog(response) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)
      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.closePageDialog
        })
      } else {
        dialog.message.push(locale.getMessage(`message.forgotPasswordSendText`))
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: this.closePageDialog
        })
        this.closeForgotPasswordDialog()
      }
      this.openPageDialog(dialog)
    },
    resetPasswordResponseCompleteDialog(response) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)
      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.closePageDialog
        })
      } else {
        let self = this
        dialog.message.push(locale.getMessage(`message.resetPasswordComplete`))
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: self.closeResetPasswordSuccessDialog
        })
      }
      this.closeResetPasswordDialog()
      this.openPageDialog(dialog)
    },
    closeResetPasswordSuccessDialog() {
      let self = this
      this.closePageDialog()
      setTimeout(function() {
        window.location.reload()
      }, 1)
    },
    registerResponseCompleteDialog(response) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)
      if (response.code != 0 && !response.success) {
        if (response.code == -1) {
          dialog.message.push(locale.getMessage('fieldErrorMessage.invalidCaptchaCode'))
        } else {
          dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        }
        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.closePageDialog
        })
        this.openPageDialog(dialog)
        if (response.code == -1) {
          this.registerErrorMsg = locale.getMessage('fieldErrorMessage.invalidCaptchaCode')
        } else {
          this.registerErrorMsg = errorCodeHelper.getErrorCodeDesc(response.code)
        }
      } else {
        this.TrackJayaS2S()
        let $k = this
        this.registerSuccessDialogShow = true
        setTimeout(function() {
          $k.registerSuccessDialogShow = false
          $k.$router.push({
            name: ROUTE_NAME.FUNDS
          })
        }, 5000)
      }
    },
    verifyContactResponseCompleteDialog(response) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.${response.action}`)
      if (response.code != 0 && !response.success) {
        dialog.message.push(errorCodeHelper.getErrorCodeDesc(response.code))
        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: this.closePageDialog
        })
      } else {
        dialog.message.push(locale.getMessage(`message.contactVerificationComplete`))
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: this.closePageDialog
        })
      }
      this.closeResetPasswordDialog()
      this.openPageDialog(dialog)
    }
  }
}
</script>

<style lang="scss">
.breakWord-onspace {
  white-space: normal;
}

.vip-badge-mobile {
  .v-badge {
    margin-top: 11px;
  }
}

.v-btn--is-elevated {
  box-shadow: 0px 0px !important;
}

.coin_box_gif {
  position: fixed;
  bottom: 32%;
  right: 10px;
  z-index: 200;
  cursor: pointer;
}

.grab_coin_icon {
  animation: swing 1.2s;
  animation-iteration-count: 3;
}

@keyframes swing {
  20% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

// .v-badge__badge {
//   bottom: 14px !important;
// }

.language_desktop {
  position: absolute;
  top: 70px;
  right: 3.5%;
}

.download-bar {
  position: fixed;
  z-index: 5;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0px 12px;
  background-color: #f5f5f5;
  line-height: 1;

  .download-bar-button {
    background: #9584ff !important;
    color: #fff;
  }
}

.download-bar-height {
  margin-top: 60px;
}

.download-bar-height-body {
  margin-top: 104px;
}

.icon_middle_nav {
  width: 30px;
  stroke: white;
  fill: white;
}

.icon_btm_nav {
  width: 19px;
  height: 19px;
  margin-top: 8px;
  //stroke: #fff;
  fill: var(--v-text_color-base);

  &.chat {
    stroke: white;
    fill: white;
    width: 23px;
    margin-bottom: -8px;
  }
}

.icon_normal {
  width: 25px;
  fill: white;
  color: black;
  stroke: #e4282f;
}

.icon_large {
  width: 35px;
  fill: white;
  color: black;
  margin-top: -5px;
}

.gold-gradient {
  background: transparent linear-gradient(180deg, #ffa15c 0%, #e2d200 100%) 0% 0% no-repeat padding-box;
  font-weight: bold;
}

.btn_img_lc {
  width: 30px;
  padding-bottom: 0;
  padding-left: 5px;
  margin-left: 10px;
}

.gold-color {
  background-color: #b87953 !important;
}

.yellow-color {
  background-color: #fddd04 !important;
}

.v-btn.theme-button.liveChatButton {
  background: transparent linear-gradient(90deg, #fa786a 0%, #c83563 50%, #86286d 100%) 0% 0% no-repeat padding-box !important;
  border-radius: 15px;
}

.mobile-member-info-box {
  padding: 5px 15px;
  background: #121734;
  box-shadow: 0px 3px 6px #00000042;
  border-radius: 8px;

  .mobile-member-info-box-text {
    font-size: 15px;
  }

  .mobile-member-info-box-text-icon {
    font-size: 12px;
  }

  .mobile-member-info-box-balance {
    font-size: 15px;
    font-weight: bold;
  }

  .mobile-member-info-box-icon {
    height: auto !important;

    .v-icon {
      font-size: 28px;
    }
  }
}

.liveChatButton {
  width: 200px;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  z-index: 1;
  min-height: auto !important;
  position: fixed;
  height: auto;
  top: 30%;
  right: -80px;
  transform: rotate(-90deg);

  span {
    font-size: 24px;
    font-weight: normal;
  }
}

.header-row {
  display: flex;
  flex-direction: column;
  padding-left: 1em;
  padding-right: 1em;
}

button {
  &.round {
    border-radius: 50% !important;
  }

  &.buttonWhite {
    box-shadow: none;
  }

  span {
    letter-spacing: 0;
  }
}

.v-btn.primary-button {
  border-radius: 10px;
  color: white;
  text-transform: capitalize; // border-radius: 25px;
  width: 100%;
  font-size: 16px;
}

.sticky-header {
  position: sticky;
  top: 0;
}

.brand-logo {
  position: absolute;
  left: 3%;
  transform: translateX(-3%);
  z-index: 1;
  top: -1%;
  width: 300px;
  margin-top: 20px;
}

.mobile-brand-logo {
  width: 250px;
}

.img_flag_select {
  width: 23px;
  height: 17px;
  margin-left: -10px;
  margin-right: 4px;
}

.member-menu-divider {
  border-color: white !important;
}

.div_header {
  background-color: #2d3487;
  padding-top: 20px;
}

.splitter_header {
  padding: 0;
  background-color: #3a3eb1;
  margin: 0 auto;
}

.header-icon-button-link {
  height: 40px !important;
  min-width: auto !important;
  width: 40px !important;
  margin-left: 8px;
}

.header-icon-button-more {
  height: 40px !important;
}

.status-bar {
  position: fixed;
  z-index: 5;
  height: 30px;
  width: 100%;
  background-color: var(--v-base-base);
  font-size: 14px;

  .status-bar-wrapper {
    width: 100%;
    max-width: 1240px;
    margin: auto;
  }

  .status-bar-language {
    .v-btn {
      background: rgba(255, 255, 255, 0.3);
      height: auto;
    }

    .v-btn:hover {
      background-color: rgba(13, 84, 62, 1);
    }

    .v-btn__content {
      text-transform: capitalize;
    }
  }

  .status-bar-icon {
    display: flex;
    position: relative;
    //color: #d5d5d5;
    color: var(--v-text_color-darken2);
    padding: 0px 8px;
    cursor: pointer;

    i {
      padding: 0px 4px 0px 0px;
      //color: #d5d5d5;
      color: var(--v-text_color-darken2);
    }

    .v-image {
      margin-right: 4px;
    }
  }

  .status-bar-icon:hover {
    //color: #ffffff;
    color: var(--v-text_color-lighten1);

    i {
      //color: #ffffff;
      color: var(--v-text_color-lighten1);
    }
  }

  .status-bar-icon:not(:last-child)::before {
    content: '';
    position: absolute;
    top: 20%;
    right: 0;
    width: 1px;
    height: 60%;
    border-right: 1px solid var(--v-text-base);
    opacity: 0.8;
  }
}

.header-top {
  height: 80px;
  background: linear-gradient(to bottom, var(--v-primary-lighten2), var(--v-primary-base));
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 5;

  .v-toolbar__content {
    max-width: 1240px;
    width: 100%;
    margin: auto;
    padding: 0px;
  }

  .theme--dark.v-btn.v-btn--icon {
    color: var(--v-text_reverse-base);
  }
}

.header-column {
  color: var(--v-text_reverse-base);

  .is-login-header {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .refresh-balance {
    transition: transform 0.5s;
  }

  .refresh-balance:hover {
    transform: rotate(215deg);
  }

  .invisible-button {
    height: 1.5rem;
    min-width: 120px;
    padding: 4px 12px;
    cursor: pointer;
    opacity: 1;

    .v-btn__content {
      opacity: 1 !important;
    }

    label {
      cursor: pointer;
    }
  }

  .invisible-button:hover {
    border: 1px solid var(--v-secondary-base);
    border-radius: 8px;
  }
}

.account-actions {
  display: flex;

  a {
    margin-left: 5px;
    margin-right: 5px;
    text-decoration: none;
  }

  .flex-column {
    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 12px;

    img {
      height: 35px;
    }
  }
}

.login-form .v-messages__message {
  font-size: 9px !important;
}

.header-bottom {
  min-height: 100px;
  color: #ffffff;
  background: transparent linear-gradient(180deg, #4145c4 0%, #121734 150%) 0% 0% no-repeat padding-box;
  position: sticky;
  top: 0;
  z-index: 15;
}

.side-navigation-drawer {
  $side-nav-p: 0px 20px;

  .list-item-container {
    padding: $side-nav-p;
  }

  .side-nav-logo {
    background-color: #303030;
    padding: 12px 32px;
    margin: 0px !important;
    border-radius: 0px !important;
  }

  .v-list {
    padding: 0px;
  }

  .v-list-item__title {
    color: var(--v-secondary-base);
  }

  a {
    margin: 0px !important;

    &.v-list-item,
    &.v-list-item--link {
      border-radius: 0;
    }

    &.v-list-item::before,
    &.v-list-item--link::before {
      border-radius: 0;
    }

    &.v-list-item--active {
      //color: #ffffff !important;
      //background-color: var(--v-secondary-base);
    }
  }

  .mobile_list_menu {
    .v-list-item__icon {
      margin: 12px 0;
    }
  }

  .v-list-item__icon:first-child {
    margin-right: 12px;
  }

  .side-menu-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .side-menu-icon {
    width: 36px;
    height: 36px;
  }

  .vip-progress-bar {
    .v-progress-linear__background {
      background-color: #ebebeb !important;
      border-color: #ebebeb !important;
      opacity: 1 !important;
    }

    .v-progress-linear__determinate {
      border-radius: 10px;
    }

    label {
      color: #acacac;
    }
  }
}

.bottom-navigator {
  position: fixed;
  bottom: 0;
  z-index: 4;
  width: 100%;
  background-color: #514bbe;

  .bottom-navigator-before-login {
    height: 60px;
    background-color: #514bbe;

    .bottom-navigator-before-login-first {
      color: black;
      background-color: #ddd;
      border: 1px solid #ddd;
    }

    .bottom-navigator-before-login-second {
      color: white;
      background-color: var(--v-secondary-base);
    }

    .bottom-navigator-before-login-third {
      color: white;
      //border: 1px solid var(--v-primary-base);
    }
  }

  .theme--light.v-btn.v-btn--has-bg {
    background-color: transparent;
  }

  a {
    //height: 50px !important;
    letter-spacing: 1px;
    padding: 0 !important;
    display: block;
    border-color: var(--v-base_3-base);
    text-decoration: none;

    &::before {
      opacity: 0 !important;
    }

    // &.link-active {
    //   border-color: #8C8CDB;
    //   .icon_btm_nav {
    //     fill: #8C8CDB;
    //     stroke: #8C8CDB;
    //     ;
    //   }
    //   .v-icon {
    //     color: #8C8CDB;
    //   }
    //   p {
    //     color: #8C8CDB;
    //   }
    // }
    p {
      margin: -2px 0 0 0;
      text-transform: capitalize;
      color: white;
    }
  }
}

.mobile-header-detail {
  // margin-top: 36px !important;
  background-color: #fff;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 12px;
  min-height: 46px;

  .header-wallet {
    font-size: 14px;
    padding: 15px 35px 15px 15px;
  }

  .header-wallet-icon {
    margin-left: -25px;
  }
}

.mobile-header-rightBtns {
  justify-content: flex-end;
}

@media (max-width: 1499px) {
  .div_header {
    padding-top: 0;
  }

  .language_desktop {
    top: 60px;
    right: 4%;
  }
}

@media (max-width: 1263px) {
  .language_desktop {
    right: 6%;
  }

  .header-icon-button-link {
    height: 35px !important;
    width: 35px !important;
    margin-left: 4px;
  }

  .header-icon-button-more {
    height: 35px !important;
    margin-left: 4px;
    min-width: auto !important;
    width: 30px !important;

    .v-icon {
      height: 15px !important;
      font-size: 28px !important;
    }
  }

  .header-text {
    font-size: 13px !important;
  }
}

@media (max-width: 1024px) {
  .header-text-statis-word {
    display: none;
  }
}

@media (max-width: 599px) {
  .header-top {
    height: 60px;
    // background: var(--v-primary-base) !important;

    .v-toolbar__content {
      padding: 4px 16px;
    }

    .toolbar-icon {
      color: var(--v-text_reverse-base);
    }
  }

  .v-btn.primary-button {
    font-size: 12px;
    width: 100%;
  }

  .language_desktop {
    position: absolute;
    top: 50px;
    right: 13%;
  }

  .liveChatButton {
    display: none;
  }

  .header {
    height: 50px;
    background-image: linear-gradient(to right, var(--v-primary-base), var(--v-primary_3-base));
    padding: 0 15px;

    .mobile-brand-logo {
      width: auto !important;
      height: 25px !important;
      margin-top: 5px;
    }

    .header-mobile-livechat {
      position: absolute;
      top: 0;
      right: 15px;
      height: 100%;

      .v-icon {
        font-size: 25px;
        padding-right: 5px;
      }

      .v-btn__content {
        font-size: 16px;
      }
    }
  }

  .v-btn.v-size--default {
    font-size: 14px;
  }
  .v-btn.v-btn--icon.v-size--default {
    width: 40px !important;
  }
}

@media (max-width: 599px) {
  .header {
    padding: 0 15px;

    .header-mobile-livechat {
      .v-icon {
        font-size: 25px;
      }

      .v-btn__content {
        font-size: 16px;
      }
    }
  }

  .mobile-brand-logo {
    margin-top: -10px;
    margin-left: -10px;
    width: 200px;
  }

  .profile-alert-success {
    font-size: 14px;
  }

  .profile-alert-failed {
    font-size: 14px;
  }

  .bottom-navigator {
    background-color: #5b52c7;

    .v-btn.v-btn--has-bg {
      background: transparent;

      .v-btn__content {
        font-size: 12px;
        text-transform: capitalize;

        p {
          margin: 0;
        }
      }
    }
  }
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026';
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}

.buttonPrimary {
  color: white !important;
}
</style>
